import React, { useEffect, useState } from "react";
import arrowImg from "../assets/img/arrow.png"


// const InsightDropdown = ({ options, onInsghtSelect, homePageInsight, getUpdatedInsight, showMenu, setShowMenu }) => {
const InsightDropdown = React.forwardRef((props, ref) => {
    const { options, onInsghtSelect, homePageInsight, getUpdatedInsight, showMenu, setShowMenu, insightDataFromApi, updateBuildForApi } = props;

    // const [showMenu, setShowMenu] = useState(false)
    const [placeholder, setPlaceholder] = useState("Choose an insight")

    useEffect(() => {
        
        if(insightDataFromApi.hasData){
            // console.log('hello', insightDataFromApi, options)
            options.forEach( option => {
                option.submenu.forEach( sub =>{
                    if(sub.name == insightDataFromApi.insight_selection.insight){
                        insightSelected(null, sub, option.id, true)
                    }
                })
            })
        }
        const handler = () => setShowMenu(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler)
        }
    },[insightDataFromApi])

    const toggleInsightDropdown = (e) => {
        e.stopPropagation();
        setShowMenu(!showMenu)
    }

    const handleMainInsightClick = (e) => {
        e.stopPropagation();
    }

    const insightSelected = (e, submenu, optionId, fromApi = false) => {
        // console.log(e, submenu, optionId, fromApi  )
        if(fromApi){
            onInsghtSelect(optionId, submenu, true)
            setPlaceholder(submenu.name)
            homePageInsight(submenu.name)
            getUpdatedInsight(submenu.name)
            updateBuildForApi('insight')
        }else{
            e.stopPropagation();
            onInsghtSelect(optionId, submenu, e.target.checked)
            if (e.target.checked) {
                setPlaceholder(submenu.name)
                homePageInsight(submenu.name)
                getUpdatedInsight(submenu.name)
            } else {
                setPlaceholder("Choose an insight")
            }
        }
        
    }

    const triggerClickById = (id) => {
        const checkbox = document.getElementById("flexCheckChecked" + id);
        // console.log('Checkbox id :',"flexCheckChecked" + id)
        // console.log('Checkbox :',checkbox)
        if (checkbox) {
            checkbox.click();
        }
    };

    // Expose the handleClick function via the ref
    React.useImperativeHandle(ref, () => ({
        setPlaceholder,
    }));

    useEffect(() => {
        // You can place any initialization code here
        // This will be executed once after the component is mounted
        // console.log('Component mounted');
        // Optionally, you can call triggerClickById here if you want it to run after mount
        // triggerClickById(/* id */);
      }, []);

    

    return (
        <div className="dropdown">
            <div className="d-grid gap-2">
                <button className="btn btn-lg text-dark txt-14px pr-10px btn-outline-secondary dropdown-toggle font-14p btn-w200p font-bold500" onClick={e => toggleInsightDropdown(e)} type="button" >
                    {placeholder}
                </button>
                {
                    showMenu && (
                        <ul className="dropdown-menu show border-0 cst-top-position">
                            {
                                options.map(option => (
                                    <li key={option.id} className="dropdown-item lable" ><div className="mb-2" onClick={e => handleMainInsightClick(e)}>{option.name}  <img src={arrowImg} alt="" className="float-end img-mt-7px " /></div>
                                        <div className="dropdown-submenu">
                                            <ul className="dropdown-menu show border-0" onClick={e => handleMainInsightClick(e)}>
                                                {
                                                    option.submenu.map(submenu => (
                                                        <li className="dropdown-item" key={submenu.id}>
                                                            <div className="form-check">
                                                                <input className="form-check-input cursor" type="checkbox" onChange={e => insightSelected(e, submenu, option.id)}
                                                                    value="" id={"flexCheckChecked" + submenu.id} checked={submenu.isSelected} />
                                                                <label className="form-check-label w-100 cursor" htmlFor={"flexCheckChecked" + submenu.id} onClick={e => handleMainInsightClick(e)}>
                                                                    {submenu.name}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
            </div>
        </div>
    );
});

export default InsightDropdown;