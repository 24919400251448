import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/css/font.css'
import './assets/css/styles.css'
import reportWebVitals from './reportWebVitals';
import Home from './Home/Home';
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import './assets/css/ui-styles.css'
import store from './store/store'
import { Provider } from 'react-redux';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
		<Home />
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
