import React from 'react';
import { useState } from 'react';

import tour_closeImg from "../assets/img/tour_close.png";
import originalGif from "../assets/img/original.gif";
import tour_build_screenImg from "../assets/img/tour_build_screen.png";
import step_1Gif from "../assets/img/step_1.gif";
import step_2Gif from "../assets/img/step_2.gif";
import step_3Gif from "../assets/img/step_3.gif";

const TourModalComponent = () => {

    const [step, setStep] = useState(1);
    const totalSteps = 5;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const handleFinish = () => {
        // Handle finish action
        // Close modal, etc.
        setStep(1);
    };

    return (
        <div>
            {/* New insight popup */}
            <div className="modal fade" id="tourModal" tabIndex="-1" aria-labelledby="tourModal" aria-hidden="true">
                <div className="modal-dialog tour-guide-modal">
                    <div className="modal-content tour-guide-modal-content">
                        <div className="modal-body tour-guide-modal-body">
                            <div className={step == 1 ? '' : 'hide-tab-div'}>
                                <span className="dot-close">
                                    <img src={tour_closeImg} className="dot-img" data-bs-dismiss="modal" />
                                </span>
                                <div className="tour-guide-media">
                                    <img src={originalGif} className="gif-img" />
                                </div>
                                <div className="tour-guide-text">
                                    <div className="tour-guide-heading">Welcome to VehiclePlannerPlus</div>
                                    <div className="tour-guide-paragraph">We'd like to take 60 seconds to show you how to use the
                                        tool. Any query has 3 quick steps, Insight, Feature, Vehicle.</div>
                                    <div data-dismiss="modal" data-bs-dismiss="modal"
                                        className="tour-guide-paragraph tour-guide-navigation-left">Show me next time</div>
                                    <div className="tour-guide-navigation-right" onClick={handleNext}>Let’s do it</div>
                                </div>
                            </div>
                            <div className={step == 2 ? '' : 'hide-tab-div'}>
                                <span className="dot-close">
                                    <img src={tour_closeImg} className="dot-img" data-bs-dismiss="modal" />
                                </span>
                                <div className="tour-guide-media-pt70">
                                    <center>
                                        <img src={tour_build_screenImg} className="tour-img-main" />
                                    </center>
                                </div>
                                <div className="tour-guide-text">
                                    <div className="tour-guide-heading">For this example, we’ll start with a standard question
                                    </div>
                                    <div className="tour-guide-paragraph">
                                        “What is the
                                        <span className="tour-guide-color-black"> Penetration Rate </span>
                                        for
                                        <span className="tour-guide-color-yellow"> Apple CarPlay </span>
                                        for
                                        <span className="tour-guide-color-blue"> Model Year 19 Ford &amp; GMC</span>
                                        ?”
                                    </div>
                                    <div className="tour-guide-paragraph tour-guide-navigation-left-h72" onClick={handleBack}>Back</div>
                                    <div className="tour-guide-navigation-right-h72" onClick={handleNext}>Next</div>
                                </div>
                            </div>
                            <div  className={step == 3 ? '' : 'hide-tab-div'}>
                                <span className="dot-close">
                                    <img src={tour_closeImg} className="dot-img" data-bs-dismiss="modal" />
                                </span>
                                <div className="tour-guide-media">
                                    <img src={step_1Gif} className="gif-img" />
                                </div>
                                <div className="tour-guide-text">
                                    <div className="tour-guide-heading">Step 1: Choosing an insight</div>
                                    <div className="tour-guide-paragraph">The insight describes the data you want, like
                                        Penetration Rate</div>
                                    <div className="tour-guide-paragraph tour-guide-navigation-left-h72" onClick={handleBack}>Back</div>
                                    <div className="tour-guide-navigation-center-pt74">
                                        <span className="dot-black">

                                        </span>
                                        <span className="dot-grey">

                                        </span>
                                        <span className="dot-grey">

                                        </span>
                                    </div>
                                    <div className="tour-guide-navigation-right-h72" onClick={handleNext}>Next</div>
                                </div>
                            </div>
                            <div className={step == 4 ? '' : 'hide-tab-div'}>
                                <span className="dot-close">
                                    <img src={tour_closeImg} className="dot-img" data-bs-dismiss="modal" />
                                </span>
                                <div className="tour-guide-media">
                                    <img src={step_2Gif} className="gif-img" />
                                </div>
                                <div className="tour-guide-text">
                                    <div className="tour-guide-heading">
                                        <span className="tour-guide-color-yellow">Step 2:</span> Choosing features
                                    </div>
                                    <div className="tour-guide-paragraph">The feature list allows you to select the vehicle
                                        features you are interested in, like Apple CarPlay</div>
                                    <div className="tour-guide-paragraph tour-guide-navigation-left-h72" onClick={handleBack}>Back</div>
                                    <div className="tour-guide-navigation-center-pt74">
                                        <span className="dot-black">

                                        </span>
                                        <span className="dot-black">

                                        </span>
                                        <span className="dot-grey">

                                        </span>
                                    </div>
                                    <div className="tour-guide-navigation-right-h72" onClick={handleNext}>Next</div>
                                </div>
                            </div>
                            <div className={step == 5 ? '' : 'hide-tab-div'}>
                                <span className="dot-close">
                                    <img src={tour_closeImg} className="dot-img" data-bs-dismiss="modal" />
                                </span>
                                <div className="tour-guide-media">
                                    <img src={step_3Gif} className="gif-img" />
                                </div>
                                <div className="tour-guide-text">
                                    <div className="tour-guide-heading">
                                        <span className="tour-guide-color-blue">Step 3:</span> Choosing vehicles
                                    </div>
                                    <div className="tour-guide-paragraph">The vehicle list shows all of the vehicles and segments
                                        you can use to view the data, like Model Year 19 Ford &amp; GMC</div>
                                    <div className="tour-guide-paragraph tour-guide-navigation-left" onClick={handleBack}>Back</div>
                                    <div className="tour-guide-navigation-center">
                                        <span className="dot-black">

                                        </span>
                                        <span className="dot-black">

                                        </span>
                                        <span className="dot-black">

                                        </span>
                                    </div>
                                    <div data-dismiss="modal" className="tour-guide-navigation-right" data-bs-dismiss="modal">Finish</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default TourModalComponent;
