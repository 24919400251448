import { createStore } from 'redux';
import { actionTypes } from './actions';



const appData = {
    insightsDropdownData: [
        {
            id: 1, name: 'Availability', isInsight: false, submenu: [
                { id: 2, name: 'Percentage of Models', isInsight: true, isSelected: false },
                { id: 3, name: 'Percentage of trims', isInsight: true, isSelected: false },
                { id: 4, name: 'Yes / No', isInsight: true, isSelected: false },
            ]
        },
        {
            id: 5, name: 'Fitment', isInsight: false, submenu: [{ id: 6, name: 'Fitment Type', isInsight: true, isSelected: false }]
        },
        {
            id: 7, name: 'Penetration Rate', isInsight: false, submenu: [
                { id: 8, name: 'By Feature', isInsight: true, isSelected: false },
                { id: 17, name: 'By Package (Export)', isInsight: true, isSelected: false },
                { id: 19, name: 'By Feature & Package (Export)', isInsight: true, isSelected: false },
                { id: 20, name: 'Trim Share', isInsight: true, isSelected: false },
            ]
        },
        {
            id: 9, name: 'Specification', isInsight: false, submenu: [
                { id: 10, name: 'Average', isInsight: true, isSelected: false },
                { id: 11, name: 'Maximum', isInsight: true, isSelected: false },
                { id: 12, name: 'Minimum', isInsight: true, isSelected: false },
            ]
        },
        {
            id: 13, name: 'Pricing', isInsight: false, submenu: [
                { id: 14, name: 'Maximum MSRP', isInsight: true, isSelected: false },
                { id: 15, name: 'Minimum MSRP', isInsight: true, isSelected: false },
                { id: 16, name: 'Minimum Package Pricing', isInsight: true, isSelected: false },
                { id: 18, name: 'Pricing Ladder', isInsight: true, isSelected: false },
                
            ]
        },
    ],
    selectedInsight: "",
    featureCategories: [],
    featuresData: [],
    selectedFeatures: [],
    vehiclesData: [],
    selectedVehicles: [],
    dropdownsController: {
        showInsights: false,
        showfeatures: false,
        showVehicles: false
    }

};

const updateMyAppData = (state = appData, action) => {

    if (action.type == actionTypes.UPDATE_CATEGORIES_FROM_API) {
        state.featureCategories = [...action.payload]
    }
    if (action.type == actionTypes.UPDATE_FEATURES_FROM_API) {
        state.featuresData = [...action.payload]
    }
    if (action.type == actionTypes.INSIGHT_SELECTED) {
        //// console.log("store", action)
        if (action.payload.isChecked) {
            state.selectedInsight = action.payload.submenu.name
        } else {
            state.selectedInsight = ""
        }
        let data = state.insightsDropdownData.map(insight => {
            if (insight.id == action.payload.insightId) {
                let submenu = insight.submenu.map(sub => {
                    if (sub.id == action.payload.submenuId) {
                        sub.isSelected = action.payload.isChecked;
                        // if(action.payload.isChecked){
                        //     state.selectedInsight = {...submenu}
                        // }
                    } else {
                        sub.isSelected = false;
                    }
                    return sub;
                });
                insight.submenu = [...submenu]
            } else {
                let submenu = insight.submenu.map(sub => {
                    sub.isSelected = false;
                    return sub;
                })
                insight.submenu = [...submenu]
            }
            return { ...insight };
        })
        // console.log([...data])
        state.insightsDropdownData = [...data]
    }
    if (action.type == actionTypes.INSIGHT_SELECTED_FEATURES_UPDATED) {
        state.selectedFeatures = [...action.payload]
    }
    if (action.type == actionTypes.INSIGHT_SELECTED_VEHICLES_UPDATED) {
        state.selectedVehicles = [...action.payload]
    }
    return state;
};

const store = createStore(updateMyAppData, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

//const store = createStore(updateMyAppData);

// store.subscribe(() => {
// 	localStorage.setItem('appData', JSON.stringify(store.getState()));
// });

export default store;
