import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';

import exportInfoImg from "../assets/img/export_info.png";

const DownloadComponent = forwardRef((props, ref) => {
    const { onShow, getInshgtData } = props;

    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        showModal() {
            // You can perform actions before showing the modal
            onShow && onShow();

            // Then show the modal
            setShowModal(true);

        },
        hideModal() {

            // Then show the modal
            setShowModal(false);

        },
    }));

    const dialogClassName = 'modal-dialog-mt-15'
    return (
        <div>

            {/* Modal */}
            <Modal show={showModal} dialogClassName={dialogClassName} onHide={() => setShowModal(false)} onShow={onShow} size="lg" >
                <Modal.Body className=" cell-modal-body">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="cell-modal-info-img-div">
                                <center>
                                    <img src={exportInfoImg} className="cell-modal-info-img" />
                                </center>
                            </div>
                            <div className="cell-modal-heading">
                                Export data to Excel
                            </div>
                        </div>
                    </div>
                    <div className="row cell-modal-info-div">
                        <div className="col-md-12">
                            <div className="cell-modal-info">
                                This insight is currently only available as a Excel download, as we are still building the online interface. Click on download to begin exporting the report.
                            </div>
                        </div>
                    </div>
                    <div className="row cell-modal-footer-div">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6 cell-modal-buttons">
                            <button type="button" className="cell-modal-export" onClick={() => setShowModal(false)}>Cancel</button>
                            <button type="button" data-dismiss="modal" className="cell-modal-export" onClick={getInshgtData}>Download</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
});

export default DownloadComponent;