import React from 'react';

import home_vpp_logo_iconImg from "../assets/img/home_vpp_logo_icon.png";
import vpp_logo_whiteImg from "../assets/img/vpp_logo_white.png";
import build_navImg from "../assets/img/build_nav.png";
import blog_navImg from "../assets/img/blog_nav.png";
import notification_navImg from "../assets/img/notification_nav.png";
import help_navImg from "../assets/img/help_nav.png";
import appData from '../utils/appData';

const SideNav = ({ isOpen, onClose, onMouseEnter, onMouseLeave }) => {
    return (
        <div className={`side-nav ${isOpen ? 'open' : ''}`} onMouseLeave={onClose} onMouseEnter={onMouseEnter}>

            <div className="nav-logo">
                <a href={appData.VPP_DOMAIN + "home"} className="nav-link">
                    <img src={home_vpp_logo_iconImg} alt="vehicleplannerplus logo" className="img img-responsive img-logo-icon" />
                    <img src={vpp_logo_whiteImg} alt="vehicleplannerplus logo" className="img img-responsive img-logo" />
                </a>
            </div>

            {/* Add your menu items here */}
            <ul className='side-nav-ul'>
                <a  href="#" className="nav-link">
                    <li className='side-nav-li side-nav-active'>
                        <img src={build_navImg} className="img img-responsive left-nav-image" />
                        <span className="side-nav-text">Build</span>
                    </li>
                </a>
                <a  href={appData.VPP_DOMAIN + "blog"} className="nav-link">
                    <li className='side-nav-li'>
                        <img src={blog_navImg} className="img img-responsive left-nav-image" />
                        <span className="side-nav-text">Blog</span>
                    </li>
                </a>
                <a  href={appData.VPP_DOMAIN + "home?show_noti=yes"} className="nav-link">
                    <li className='side-nav-li'>
                        <img src={notification_navImg} className="img img-responsive left-nav-image" />
                        <span className="side-nav-text">Notifications</span>
                    </li>
                </a>
                <a  href={appData.VPP_DOMAIN + "help"} className="nav-link">
                    <li className='side-nav-li'>
                        <img src={help_navImg} className="img img-responsive left-nav-image" />
                        <span className="side-nav-text">Help</span>
                    </li>
                </a>
                {/* Add more items as needed */}
            </ul>
        </div>
    );
};

export default SideNav;
