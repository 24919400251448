const { getOpenAxios } = require("../utils/apis")

const axios = getOpenAxios();

export const saveInsightDataApi = async (data) => {
    return await axios.post("/insightaction/save", data)
}

// Save favorite insight API call
export const saveFavoriteInsightApi = async (data) => {
    return await axios.post("/insightaction/save-favorite", data);
};

// Remove favorite insight API call
export const removeFavoriteInsightApi = async (data) => {
    return await axios.post("/insightaction/remove-favorite", data);
};

// List insight API call
export const listInsightApi = async (user_id, limit = 0) => {
    return await axios.get(`/insightaction/list-insight?user_id=${user_id}&limit=${limit}`);
};

// List favorite insight API call
export const listFavoriteInsightApi = async (user_id, limit = 0) => {
    return await axios.get(`/insightaction/list-favorite?user_id=${user_id}&limit=${limit}`);
};

// Get insight by ID API call
export const getInsightApi = async (user_id, itemId) => {
    return await axios.get(`/insightaction/get-insight-by-id?user_id=${user_id}&id=${itemId}`);
};