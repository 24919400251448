import { useSelector } from 'react-redux'
import HomeNav from '../Nav/HomeNav'
import Footer from './Footer'
import InsightBuilder from './InsightBuilder'
import ResultTable from './ResultTable'
import { useState } from 'react'
import Login from './Login'
import cookie from 'react-cookies'
import appData from '../utils/appData'

const Home = () => {
    
    let userIdCookie = cookie.load('user_id')
    // console.log(userIdCookie)
    if(userIdCookie != undefined){
        localStorage.setItem('userId', userIdCookie)
    }else{
        window.location = appData.VPP_DOMAIN
    }

    const [features, setFeatures] = useState([])
    const [insight, setInsight] = useState("")
    const [vehicles, setVehicles] = useState([])

    let localObj = localStorage.getItem("loginKey")

    const [isLoggedIn, setIsLoggedIn] = useState(true)
    
    return(
        <div >
            <HomeNav isLoggedIn={isLoggedIn} />
            <div className='container-fluid sbd-bg-light'>
                
                <InsightBuilder homePageUpdateFeatures={setFeatures} homePageInsight={setInsight} homePageVehicles={setVehicles} />
                
                <Footer />
            </div>
        </div>
    )
}
 
export default Home