import axios from 'axios';

const API_URL = 'https://liveapi.fastvpp.vehicleplannerplus.com'; // 'https://api.fastuat.vehicleplannerplus.com'; //'http://localhost:3030'; 'http://fastuat.vehicleplannerplus.com:3030'
const openAxios = axios.create({
	baseURL: API_URL,
});
export const getOpenAxios = () => {
	return openAxios;
};


