import React, { useState, useEffect, useRef } from 'react';
import SideNav from './SideNav';

import vpp_menu_drop_downImg from "../assets/img/vpp_menu_drop_down.png"
import appData from '../utils/appData';
import cookie from 'react-cookies'

const HomeNav = ({ isLoggedIn }) => {

    const [isSideNavOpen, setSideNavOpen] = useState(false);
    const [isTopNavOpen, setTopNavOpen] = useState(false);

    const toggleSideNav = () => {
        setSideNavOpen(!isSideNavOpen);
    };

    const toggleTopNav = () => {
        setTopNavOpen(!isTopNavOpen);
    };

    const handleMouseEnter = () => {
        setSideNavOpen(true);
    };

    const handleMouseLeave = () => {
        setSideNavOpen(false);
    };

    const handleLogout = () => {
        // Clear local storage
        localStorage.clear();

        // Redirect to the "/" route
        window.location.href = appData.VPP_DOMAIN + 'logout';
    };

    const topNavDivRef = useRef(null);

    const handleClickOutside = (event) => {
        if (topNavDivRef.current && !topNavDivRef.current.contains(event.target)) {
            setTopNavOpen(false);
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            handleClickOutside(event);
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <div>

            {
                isLoggedIn &&
                <SideNav isOpen={isSideNavOpen} onClose={toggleSideNav} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
            }
            <nav className="navbar navbar-light bg-white nav-border-bottom top-navbar-height">
                {
                    isLoggedIn &&
                    <div className="top-nav-float" onClick={toggleTopNav} ref={topNavDivRef}>
                        <span className="top-nav-div dropdown">
                            <p data-toggle="dropdown" className="top-nav-text">
                                {cookie.load('user_name')}
                                <img src={vpp_menu_drop_downImg} className="top-nav-arrow" />
                            </p>
                            <ul className={(isTopNavOpen ? 'show ' : '') + "dropdown-menu dropdown-logout"}>
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "admin"} className="logout-link">
                                            <p className="top-nav-logout">
                                                Grant Access
                                            </p>
                                        </a>
                                    </li>
                                }
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "logs"} className="logout-link">
                                            <p className="top-nav-logout">
                                                User Logs
                                            </p>
                                        </a>
                                    </li>
                                }
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "register"} className="logout-link">
                                            <p className="top-nav-logout">
                                                Registera new user
                                            </p>
                                        </a>
                                    </li>
                                }
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "customer-analytics"} className="logout-link">
                                            <p className="top-nav-logout">
                                                Customer Analytics
                                            </p>
                                        </a>
                                    </li>
                                }
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "vehicle/segment"} className="logout-link">
                                            <p className="top-nav-logout">
                                                Segmentation Management
                                            </p>
                                        </a>
                                    </li>
                                }
                                { cookie.load('user_role') == 1 && 
                                    <li className="top-nav-list">
                                        <a href={appData.VPP_DOMAIN + "blog_admin"} className="logout-link">
                                            <p className="top-nav-logout">
                                                Blog Admin Panel
                                            </p>
                                        </a>
                                    </li>
                                }

                                <li className="top-nav-list">
                                    <a href={appData.VPP_DOMAIN + "password/change"} className="logout-link">
                                        <p className="top-nav-logout">
                                            Change Password
                                        </p>
                                    </a>
                                </li>
                                <li className="top-nav-list">
                                    <a href={appData.VPP_DOMAIN + "terms"} className="logout-link">
                                        <p className="top-nav-logout">
                                            Terms and Conditions
                                        </p>
                                    </a>
                                </li>
                                {/* <li className="top-nav-list">
                                    <a href={appData.VPP_DOMAIN + "logout"} className="logout-link">
                                        <p className="top-nav-logout">
                                            Logout
                                        </p>
                                    </a>
                                </li> */}
                                


                                <li className="top-nav-list">
                                    <a href="#" onClick={handleLogout} className="logout-link">
                                        <p className="top-nav-logout">
                                            Logout
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </span>
                    </div>
                }
            </nav>
        </div>
    )
}

export default HomeNav