import React, { useEffect, useRef } from 'react';

import config_closeImg from "../assets/img/config_close.png"

import '../../node_modules/nouislider/dist/nouislider.css';
import nouislider from 'nouislider';

import CheckboxDropdown from '../InputComponent/CheckboxDropdown';


const AdvancedFiltersModalComponent = ({ vehicleDropdownData }) => {

    const sliderRef = useRef(null);
    const pipsliderRef = useRef(null);

    // // console.log('vehicleDropdownData:', vehicleDropdownData);

    useEffect(() => {
        const slider = nouislider.create(sliderRef.current, {
            start: [20, 80], // Initial range
            connect: true,   // Show the range between handles
            range: {
                'min': 0,
                'max': 100
            }
        });

        slider.on('update', (values) => {
            // // console.log(values);
        });

        return () => {
            slider.destroy(); // Clean up when component unmounts
        };

    }, []);

    useEffect(() => {
        const pipslider = nouislider.create(pipsliderRef.current, {
            start: [18, 24],
            connect: true,
            range: {
                'min': 18,
                'max': 24
            },
            pips: {
                mode: 'values',
                values: [18, 19, 20, 21, 22, 23, 24],
                density: 10
            }
        });

        return () => {
            pipslider.destroy(); // Clean up when component unmounts
        };
    }, []);

    const getCategoryValues = (category) => {
        const categoryObject = vehicleDropdownData.find(item => item.category == category);
        return categoryObject ? categoryObject.values : [];
    };

    // Example usage:
    const SegmentsValues = getCategoryValues("Segments");
    // // console.log("SegmentsValues", SegmentsValues);

    const BrandValues = getCategoryValues("Brand");
    // // console.log("BrandValues", BrandValues);

    const ModelValues = getCategoryValues("Model");
    // // console.log("ModelValues", ModelValues);
    
    const TrimValues = getCategoryValues("Trim");
    // // console.log("TrimValues", TrimValues);
    
    const BrandTypeValues = getCategoryValues("Brand Type");
    // // console.log("BrandTypeValues", BrandTypeValues);
    
    const VehicleTypeValues = getCategoryValues("Vehicle Type");
    // // console.log("VehicleTypeValues", VehicleTypeValues);
    
    const modelYearValues = getCategoryValues("Model Year");
    // // console.log("Model Year", modelYearValues);
    

    return (
        <div>

            <div className="modal fade" id="advancedFiltersModal" tabIndex="-1" aria-labelledby="advancedFiltersModal" aria-hidden="true">
                <div className="modal-dialog  modal-lg modal-dialog-centered adv-modal-dialog">
                    <div className="modal-content adv-modal-content">
                        <div className="modal-body">
                            {/* first row section */}
                            <div className="row adv-first-row">
                                <div className="col-md-12">
                                    <div className="adv-config-tab adv-filter-tab-title">Filter</div>
                                    <div className="adv-config-tab adv-config-tab-title">Configure</div>
                                    <div className="adv-config-close">
                                        <center>
                                            <img src={config_closeImg} data-dismiss="modal" data-bs-dismiss="modal" className="adv-close-img" />
                                        </center>
                                    </div>
                                </div>
                            </div>
                            {/* first row End */}

                            {/* second section - filter row  */}
                            <div className="row adv-second-row">
                                <div className="col-md-6 adv-left-col">
                                    <div className='input-component-wraper'>
                                        <label className='adv-label'>Vehicle MSRP ($)</label>
                                        <div className='input-wraper'>
                                            <input type="text" className='small-text-input-left' defaultValue="" />
                                            <input type="text" className='small-text-input-left small-text-input-right' defaultValue="" />
                                            <div ref={sliderRef}></div>
                                        </div>
                                    </div>
                                    <div className='input-component-wraper'>
                                        <label className='adv-label'>Model Year</label>
                                        <label className="container-checkbox container-checkbox-custom">
                                            Latest MY <input type="checkbox" />
                                            <span className="checkmark span-text">

                                            </span>
                                        </label>
                                        <div className='input-wraper'>
                                            <div ref={pipsliderRef}></div>
                                        </div>
                                    </div>
                                    <div className='input-component-wraper'>
                                        <label className='adv-label'>
                                            Trim Level<span className='radio-label-tootlip'>(select 1 only)</span>
                                        </label>
                                        <div className='input-wraper'>
                                            <div >
                                                <label className="container-checkbox-horizontal">Basic
                                                    <input type="radio" name='trimLVL' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                                <label className="container-checkbox-horizontal">Mid
                                                    <input type="radio" name='trimLVL' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                                <label className="container-checkbox-horizontal">Top
                                                    <input type="radio" name='trimLVL' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                                <label className="container-checkbox-horizontal">All
                                                    <input type="radio" name='trimLVL' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='input-component-wraper'>
                                        <label className='adv-label'>
                                            Fitment type<span className='radio-label-tootlip'>(select 1 only)</span>
                                        </label>
                                        <div className='input-wraper'>
                                            <div >
                                                <label className="container-checkbox-horizontal">Standard
                                                    <input type="radio" name='fitmentType' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                                <label className="container-checkbox-horizontal">Optional
                                                    <input type="radio" name='fitmentType' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                                <label className="container-checkbox-horizontal">All
                                                    <input type="radio" name='fitmentType' />
                                                    <span className="checkmark-radio span-text">

                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 adv-right-col">
                                    <CheckboxDropdown label='Vehicle Segments' options={SegmentsValues} />
                                    <CheckboxDropdown label='Brands' options={BrandValues} />
                                    <CheckboxDropdown label='Models' options={ModelValues} />
                                    <CheckboxDropdown label='Trims' options={TrimValues} />
                                    <CheckboxDropdown label='Brand Types' options={BrandTypeValues} />
                                    <CheckboxDropdown label='Vehicle Types' options={VehicleTypeValues} />

                                </div >
                            </div >
                            {/* second section - config row  */}

                            {/* third section - close, reset row  */}
                            <div className="row adv-third-row">
                                <div className="col-md-5 pull-left">
                                    <div className="col-md-2">
                                        <button type="button" className="btn adv-reset-btn">Reset</button>
                                    </div>
                                    <div className="col-md-10">

                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div>
                                        <button type="button" data-dismiss="modal" className="btn adv-cancel-btn">Cancel</button>
                                        <button type="button" data-dismiss="modal" className="btn adv-apply-btn">Apply</button>
                                    </div>
                                </div>
                            </div>
                            {/* third section - End  */}
                        </div >
                    </div >
                </div >
            </div >

        </div >

    );
}

export default AdvancedFiltersModalComponent;
