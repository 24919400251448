const { getOpenAxios } = require("../utils/apis")

const axios = getOpenAxios();

const allVehicles = {
    year: [],
    class: [],
    make: [],
    vehicle_type: [],
    segment_name: [],
    model: []
}

export const getFeaturesApi = async (data) =>{
    return await axios.post("/dropdowns/features",data)
}

export const getVehiclesApi = async (data, insight, custInsight = null) =>{
    return await axios.post("/dropdowns/vehicles", data == null ? {data: {...allVehicles, insight: insight, custInsight: custInsight}} : {data: {...data, insight: insight, custInsight: custInsight}})
}

