function TrimShareTable({trimData, resultTrimShareTableRef}){
    // console.log('trimData: ',  trimData )
    const vehConfig = [
        {key: "year", value: "Model Year"}, {key: "make", value: "Brand"}, {key: "vehicle_type", value: "Vehicle Type"},
        {key: "segment_name", value: "Segments"}, {key: "model", value: "Model"}, {key: "sbd_trim", value: "Trim"},
        {key: "class", value: "Brand Type"},
    ]

    const getColHeader = (col) =>{
        let header = col
        vehConfig.forEach( e =>{
            if( e.key == col ){
                header = e.value
            }
        })
        return header
    }

    return(
        <>
            <div className="col-12">
                <div className="card mt-4 mb-5 border-0 " >
                    <div className="card-body  " style={{ minHeight: "70.5vh" }}>
                        <div className="row">
                            <div className="col-12">
                                <div className=" sbd-trim-tbl">
                                    {
                                        trimData.length > 0 &&
                                        <table className="table table-bordered_2 " ref={resultTrimShareTableRef}>
                                            <thead>
                                                <tr>
                                                    {
                                                        Object.keys(trimData[0]).map((e, i) => (
                                                            <th key={'td_'+i}>{ getColHeader(e) }</th>
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    trimData.map( d =>(
                                                        <tr>
                                                            {
                                                                Object.keys(d).map((e, i) => (
                                                                    <td key={'td_'+i}>{d[e]}</td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrimShareTable;
