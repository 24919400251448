import React from "react";
import * as XLSX from "xlsx";

const DownloadTableExcelSheetJS = ({ filename, sheet, currentTableRef, children }) => {

    // Function to export table to Excel
    const exportToExcel = () => {
        if (!currentTableRef) {
            console.error("No table reference provided");
            return;
        }

        // Convert the table element to a worksheet
        const tableWS = XLSX.utils.table_to_sheet(currentTableRef);

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();

        // Add custom titles
        // const titleData = [
        //   ["Type of export"],
        //   ["Source: CONFIDENTIAL Message"],
        //   [`Created on: ${new Date().toLocaleString()}`],
        //   [`Exported on: ${new Date().toLocaleString()}`],
        //   [""],
        //   [""],
        // ];
        const titleData = [
            ["Source: VehiclePlannerPlus - CONFIDENTIAL"],
            [`Created on: ${new Date().toLocaleString()}`],
            [`Exported on: ${new Date().toLocaleString()}`],
            [""]
        ];

        const titleWS = XLSX.utils.aoa_to_sheet(titleData);

        // Combine title worksheet and table worksheet
        const ws = XLSX.utils.sheet_add_aoa(titleWS, XLSX.utils.sheet_to_json(tableWS, { header: 1 }), { origin: -1 });

        // Apply percentage formatting to the entire sheet (only for decimal values between 0 and 1)
        applyPercentageFormatting(ws);

        XLSX.utils.book_append_sheet(wb, ws, sheet);

        // filename = generateFilename()

        // Write the workbook to a file
        XLSX.writeFile(wb, filename);
    };

    return (
        <span>
            <span onClick={exportToExcel}>
                {children}
            </span>
        </span>
    );
};

// Function to apply percentage formatting only for decimal values between 0 and 1
const applyPercentageFormatting = (ws) => {
    const range = XLSX.utils.decode_range(ws['!ref']); // Get the entire range of the sheet

    for (let row = range.s.r; row <= range.e.r; ++row) {
        for (let col = range.s.c; col <= range.e.c; ++col) {
            const cell_ref = XLSX.utils.encode_cell({ r: row, c: col });
            const cell = ws[cell_ref];

            if (cell && cell.t === "n" && cell.v >= 0 && cell.v <= 1) {
                // Check if the cell is a decimal number between 0 and 1
                cell.z = "0%"; // Apply percentage format
            }
        }
    }
};

// Function to generate the date-time formatted filename
const generateFilename = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    }).replace(/[/,]/g, ".").replace(/[:]/g, ".");
    return `${formattedDate} VehiclePlannerPlus Insight.xlsx`;
};


export default DownloadTableExcelSheetJS;
