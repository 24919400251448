import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap';

const DownloadProcessComponent = forwardRef((props, ref) => {
    const { onShow } = props;

    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        showModal() {
            // You can perform actions before showing the modal
            onShow && onShow();

            // Then show the modal
            setShowModal(true);

        },
        hideModal() {

            // Then show the modal
            setShowModal(false);

        },
    }));

    const dialogClassName = 'modal-dialog-mt-15'
    return (
        <div>

            {/* Modal */}
            <Modal show={showModal} dialogClassName={dialogClassName} onHide={() => setShowModal(false)} onShow={onShow} size='md'>
                <Modal.Body className=" cell-modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="div-modal-heading"> File being prepared</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginBottom: "5px"}}>
                            <span className="div-modal-heading-gray"> Our engine is pulling your data together, ready to place into an excel file.</span>
                        </div>
                    </div>
                    <div className="row pull-right div-modal-padding">
                        <div className="col-md-12">
                            <button type="button" className="btn btn-primary div-modal-weight" onClick={() => setShowModal(false)}> Close </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
});

export default DownloadProcessComponent;