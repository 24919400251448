import React, { useEffect, useState, useRef } from "react";

const CheckboxDropdown = ({ label, options }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [allChecked, setAllChecked] = useState(true);
    const [checkboxStates, setCheckboxStates] = useState(
        options.reduce((acc, option) => {
            acc[option] = true;
            return acc;
        }, {})
    );

    const dropdownRef = useRef(null);

    const toggleDropdownOpen = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const toggleCheckbox = (option) => {
        const updatedCheckboxStates = { ...checkboxStates, [option]: !checkboxStates[option] };
        setCheckboxStates(updatedCheckboxStates);

        const areAllChecked = Object.values(updatedCheckboxStates).every((isChecked) => isChecked);
        setAllChecked(areAllChecked);
    };

    const toggleAllCheckbox = () => {
        const newAllChecked = !allChecked;
        setAllChecked(newAllChecked);

        const updatedCheckboxStates = Object.fromEntries(
            Object.keys(checkboxStates).map((option) => [option, newAllChecked])
        );
        setCheckboxStates(updatedCheckboxStates);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        const areAllChecked = Object.values(checkboxStates).every((isChecked) => isChecked);
        setAllChecked(areAllChecked);
    }, [checkboxStates]);

    const selectedCount = Object.values(checkboxStates).filter(Boolean).length;
    const totalCount = options.length;

    return (
        <span className="dropdown font-12" ref={dropdownRef}>
            <label className="adv-form-label-cust">{label}</label>
            <div className="pb-8px">
                <div className="custom_filter custom_filter_deselect">
                    <div className="dropdown row default-font m-2px">
                        <button
                            type="button"
                            data-toggle="dropdown"
                            className="btn btn-default dropdown-toggle dd-btn adv-dropdown-custom"
                            aria-expanded="false"
                            onClick={toggleDropdownOpen}
                        >
                            <div className="col-md-12 adv-col-12-cust">
                                <span className={allChecked ? "inner-label-all" : "inner-label-filtered"}>
                                    {allChecked ? "All" : `${selectedCount} out of ${totalCount}`}
                                </span>
                            </div>
                        </button>
                        <ul className={(isDropdownOpen ? "show" : "") + " dropdown-menu adv-custom-dropdown-menu"}>
                            <div className="col-md-12 item-box">
                                <label className="adv-container">
                                    All
                                    <input type="checkbox" checked={allChecked} onChange={toggleAllCheckbox} />
                                    <span className="adv-checkmark span-text"></span>
                                </label>
                            </div>
                            <div className="adv-custom-li-wraper">
                                {options.map((ele, i) => (
                                    <li key={i}>
                                        <div className="col-md-12 item-box">
                                            <label className="adv-container">
                                                {ele}
                                                <input type="checkbox" value={ele} checked={checkboxStates[ele]} onChange={() => toggleCheckbox(ele)} />
                                                <span className="adv-checkmark span-text"></span>
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default CheckboxDropdown;
