
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import arrowImg from "../assets/img/arrow.png"
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedFeatures } from "../store/actions";
import { List } from 'react-content-loader'

// function FeaturesDropdown({ featureCategories, featuresData, insightBuilderUpdateFeatures, showMenu, setShowMenu, selectedInsightId, setFeatureDropdownSelected, isInsightDropdownSelected, selectedFeatures, setSelectedfeatures }) {
const FeaturesDropdown = forwardRef(({ featureCategories, featuresData, insightBuilderUpdateFeatures, showMenu, setShowMenu, selectedInsightId,
    setFeatureDropdownSelected, isInsightDropdownSelected, selectedFeatures, setSelectedfeatures, tblVehicles, setVehiclesDropdownSelected, featuresNVehicleLoading,
    block2Data, setBlock2Data, block3Data, setBlock3Data, block4Data, setBlock4Data, insightDataFromApi, updateBuildForApi }, ref) => {

    // // console.log('FeaturesDropdown: featuresData: ', featuresData)
    // // console.log('FeaturesDropdown: block2Data: ', block2Data)
    // // console.log('FeaturesDropdown: block3Data: ', block3Data)

    const dispatch = useDispatch();
    // const [showMenu, setShowMenu] = useState(false)
    const [placeholder, setPlaceholder] = useState("Choose some features")
    const [block2Classes, setBlock2Classes] = useState("border-end")
    const [block3Classes, setBlock3Classes] = useState("border-end")
    const [dummyUpdate, setDummyUpdate] = useState("")

    // const [selectedFeatures, setSelectedfeatures] = useState([])
    const selectedInsight = useSelector(state => state.selectedInsight)

    // if(selectedFeatures.length > 0){
    //     updateBuildForApi('features')
    // }
    const searchInputRef = useRef(null);

    let block2LocalData = []
    let block3LocalData = []
    let block4LocalData = []

    useEffect(() => {
        // console.log('features dropdown: ', insightDataFromApi, featuresData)
        if (insightDataFromApi.hasData) {
            let selfeatures = []
            insightDataFromApi.insight_selection.features.forEach(featureID => {
                let featureToSelect = featuresData.find(item => item.sbd_feature_id === featureID);
                if (featureToSelect !== undefined) {
                    selfeatures.push({ ...featureToSelect })
                }
            })
            // console.log(selfeatures)
            updateSelectedfeatures([...selfeatures])

        }
        const handler = () => {
            setShowMenu(false)
            setDummyUpdate(" ")
        };
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler)
        }
    }, [insightDataFromApi, featuresData])

    const toggleFeaturesDropdown = (e) => {
        e.stopPropagation();

        setIsSearchMode(!isSearchMode);
        setShowSearchMenu(false)
        setSearchTerm('');

        setDummyUpdate(" ")
        if (selectedInsight != "") {
            setShowMenu(!showMenu)
        } else {

        }

    }

    const handleMainInsightClick = (e) => {
        e.stopPropagation();
    }
    const handleCategorySelect = (e, name) => {
        // // console.log(name)
        let tmpList = []
        featuresData.map(feature => {
            if (feature.vp_category == name) {
                let tmp = tmpList.find(t => t.vp_name == feature.vp_name)
                if (tmp) {
                    if (feature.vp_value != null && feature.vp_value != "") {
                        tmp.cnt = tmp.cnt + 1
                    }
                } else {
                    tmpList.push({ vp_name: feature.vp_name, cnt: feature.vp_value == '' ? 0 : 1, isChecked: false, isAllChecked: false })
                }
            }
        })
        setBlock2Data([...tmpList])
        block2LocalData = [...tmpList]
        if (tmpList.length > 12) {
            setBlock2Classes("")
        }
        setBlock3Data([])
        block3LocalData = []
    }

    const onBlock2insightSelected = (e, feature, isChecked = false) => {
        // // console.log('onBlock2insightSelected', JSON.stringify(feature), block2Data, block2LocalData )

        if (e != null) {
            isChecked = e.target.checked;
            e.stopPropagation();
        }

        let data = block2Data.map(item => {
            if (item.vp_name == feature.vp_name) {
                item.isAllChecked = isChecked;
                item.isChecked = isChecked;
                if (isChecked) {
                    addToBlock2Selected(feature);
                } else {
                    removeFromBlock2Selected(feature)
                }
            }
            return item
        })

        setBlock2Data([...data])
    }

    const addToBlock2Selected = (feature) => {
        let data = featuresData.filter(item => feature.vp_name == item.vp_name)
        // // console.log("selected", [...data])
        updateSelectedfeatures([...selectedFeatures, ...data])
    }

    const removeFromBlock2Selected = (feature) => {
        let data = selectedFeatures.filter(item => feature.vp_name != item.vp_name)
        //// console.log("after removing", [...data])
        updateSelectedfeatures([...data])
    }

    const checkIsBlock2Selected = (feature) => {
        let data = selectedFeatures.filter(item => item.vp_name == feature.vp_name)
        if (data.length > 0) {
            setFeatureDropdownSelected(true)
            return true
        } else {
            return false
        }
    }
    const checkIsBlock3Selected = (feature) => {
        let data = selectedFeatures.filter(item => item.vp_value == feature.vp_value && item.vp_name == feature.vp_name)
        if (data.length > 0) {
            setFeatureDropdownSelected(true)
            return true
        } else {
            return false
        }
    }

    const onBlock2MouseHover = (e, name, cnt) => {
        if (cnt > 0) {
            let tmpList = []
            featuresData.map(feature => {
                if (feature.vp_name == name) {
                    let tmp = tmpList.find(t => t.vp_value == feature.vp_value)
                    if (tmp) {
                        if (feature.vp_sub_value != null && feature.vp_sub_value != "") {
                            tmp.cnt = tmp.cnt + 1
                        }
                    } else {
                        if (feature.vp_value != null && feature.vp_value != "") {
                            tmpList.push({ vp_name: feature.vp_name, vp_value: feature.vp_value, cnt: feature.vp_sub_value == '' ? 0:1, isChecked: false, isAllChecked: false })
                        }
                    }
                }
            })
            let anyObj = tmpList.filter(e => e.vp_value == "Any")
            let otherObj = tmpList.filter(e => e.vp_value != "Any")
            otherObj = otherObj.sort((a, b) => {
                if (isNaN(a.vp_value) && isNaN(b.vp_value)) {
                    if (a.vp_value.toLowerCase() < b.vp_value.toLowerCase()) {
                        return -1;
                    }
                } else {
                    if (+a.vp_value < +b.vp_value) {
                        return -1;
                    }
                }
            })
            // // console.log(tmpList)
            setBlock3Data([...anyObj.concat(otherObj)])
            block3LocalData = [...anyObj.concat(otherObj)]
            if (tmpList.length > 12) {
                setBlock3Classes("")
            }
        } else {
            setBlock3Data([])
            block3LocalData = []
        }
        setBlock4Data([])
        block4LocalData = []
    }

    const onBlock3insightSelected = (e, input, isChecked = false) => {
        // console.log('block 3: ', JSON.stringify(input))
        if (e != null) {
            isChecked = e.target.checked
            e.stopPropagation();
        }

        // console.log('block 3: ', input)
        if (input.cnt > 1 && input.vp_value != "Any") {
            // populate 4
        }
        if (isChecked) {
            addToBlock3Selected(input)
        } else {
            removeFromBlock3Selected(input)
        }
    }

    const addToBlock3Selected = (feature) => {
        let data = featuresData.filter(item => (feature.vp_name == item.vp_name && feature.vp_value == item.vp_value))
        // console.log("addToBlock3Selected 223: ", [...selectedFeatures, ...data])
        updateSelectedfeatures([...selectedFeatures, ...data])
    }

    const removeFromBlock3Selected = (feature) => {
        //// console.log(selectedFeatures)
        let data = selectedFeatures.filter(item => (feature.vp_name != item.vp_name || feature.vp_value != item.vp_value))
        //// console.log("after removing", [...data])
        updateSelectedfeatures([...data])
    }

    const onBlock3MouseHover = (e, name, value, cnt) => {
        //// console.log(name, value, cnt)
        if (cnt > 1 && value != "Any") {
            let tmpList = []
            featuresData.map(feature => {
                if (feature.vp_name == name && feature.vp_value == value) {
                    let tmp = tmpList.find(t => t.vp_value == value && t.vp_name == name && t.vp_sub_value == feature.vp_sub_value)
                    if (tmp == undefined) {
                        tmpList.push(feature)
                    }
                }
            })
            let anyObj = tmpList.filter(e => e.vp_sub_value == "Any")
            let otherObj = tmpList.filter(e => e.vp_sub_value != "Any")
            otherObj = otherObj.sort((a, b) => {
                if (isNaN(a.vp_sub_value) && isNaN(b.vp_sub_value)) {
                    if (a.vp_sub_value.toLowerCase() < b.vp_sub_value.toLowerCase()) {
                        return -1;
                    }
                } else {
                    if (+a.vp_sub_value < +b.vp_sub_value) {
                        return -1;
                    }
                }
            })
            // // console.log(tmpList)
            setBlock4Data([...anyObj.concat(otherObj)])
        } else {
            setBlock4Data([])
        }
    }

    const checkIsBlock4Selected = (feature) => {
        let data = selectedFeatures.filter(item => item.vp_value == feature.vp_value && item.vp_name == feature.vp_name && item.vp_sub_value == feature.vp_sub_value)
        if (data.length > 0) {
            setFeatureDropdownSelected(true)
            return true
        } else {
            return false
        }
    }

    const onBlock4insightSelected = (e, input) => {
        e.stopPropagation();
        if (e.target.checked) {
            addToBlock4Selected(input)
        } else {
            removeFromBlock4Selected(input)
        }
    }

    const addToBlock4Selected = (feature) => {
        let data = featuresData.filter(item => (feature.vp_name == item.vp_name && feature.vp_value == item.vp_value && feature.vp_sub_value == item.vp_sub_value))
        // console.log("selected", data.vp_sub_value, selectedFeatures, data)
        updateSelectedfeatures([...selectedFeatures, ...data])
    }

    const removeFromBlock4Selected = (feature) => {
        //// console.log(selectedFeatures)
        let data = selectedFeatures.filter(item => (feature.vp_name != item.vp_name || feature.vp_value != item.vp_value || feature.vp_sub_value != item.vp_sub_value))
        //// console.log("after removing", [...data])
        updateSelectedfeatures([...data])
    }

    const updateSelectedfeatures = (features) => {
        // console.log('updateSelectedfeatures 299: ', features)
        let tmpList = []
        features.forEach(element => {
            let ele = { ...element }
            ele.sbd_feature_id = ""
            tmpList.push(ele)
        });
        const uniqueJsonObjects = getUniqueObjects(tmpList);
        //// console.log('updateSelectedfeatures', features, tmpList, uniqueJsonObjects)
        setSelectedfeatures([...features])
        dispatch(updateSelectedFeatures([...features]))
        insightBuilderUpdateFeatures([...features])
        if (tblVehicles.length) {
            setVehiclesDropdownSelected(true)
        }
        if (uniqueJsonObjects.length == 0) {
            setPlaceholder("Choose some features")
            setVehiclesDropdownSelected(false)
        }
        if (uniqueJsonObjects.length == 1) {
            setPlaceholder("1 Feature")
            updateBuildForApi('features')
        }
        if (uniqueJsonObjects.length > 1) {
            setPlaceholder(uniqueJsonObjects.length + " Features")
            updateBuildForApi('features')
        }
        // updateBuildForApi('features')
    }
    // Expose childFunction to parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        updateSelectedfeatures, addToBlock2Selected, addToBlock3Selected, addToBlock4Selected
    }));
    function getUniqueObjects(jsonArray) {
        // console.log('getUniqueObjects: ', jsonArray)
        const seenObjects = new Set();
        return jsonArray.filter(obj => {
            // Convert each object to a string representation for comparison
            const objString = JSON.stringify(obj);

            // Check if the string representation is unique
            if (!seenObjects.has(objString)) {
                seenObjects.add(objString);
                return true;
            }

            return false;
        });
    }

    // Search-box
    const [showSearchMenu, setShowSearchMenu] = useState(false)
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [searchBlock1Data, setSearchBlock1Data] = useState([]);
    const [searchBlock2Data, setSearchBlock2Data] = useState([]);
    const [searchBlock3Data, setSearchBlock3Data] = useState([]);

    useEffect(() => {

        if (isSearchMode) {
            // Set focus on the input when entering search mode
            searchInputRef.current.focus();
        }
    })

    const toggleSearchMode = () => {
        setIsSearchMode(!isSearchMode);
        setSearchTerm('');
    };

    let alreadyAdded = [];

    const searchTermUpdate = (term) => {
        let data = featuresData.filter(item => (
            item.vp_name.toLowerCase().includes(term.toLowerCase()) ||
            item.vp_category.toLowerCase().includes(term.toLowerCase()) ||
            (item.vp_value != null && item.vp_value.toLowerCase().includes(term.toLowerCase())) ||
            (item.vp_sub_value != null && item.vp_sub_value.toLowerCase().includes(term.toLowerCase()))
        ));

        data = data.map(item => {
            const termLower = term.toLowerCase();
            const vpCategoryLower = item.vp_category.toLowerCase();
            const vpNameLower = item.vp_name.toLowerCase();
            const vpValueLower = item.vp_value != null ? item.vp_value.toLowerCase() : null;
            const vpSubValueLower = item.vp_sub_value != null ? item.vp_sub_value.toLowerCase() : null;

            let foundLevel = null;
            let cnt = 0;
            let tempData = [];

            if (vpCategoryLower.includes(termLower)) {
                foundLevel = 1;
                // Count where vp_name are same
                tempData = data.filter(tempItem => (tempItem.vp_category == item.vp_category));
                cnt = tempData.length;
            } else if (vpNameLower.includes(termLower)) {
                foundLevel = 2;
                // Count where vp_name are same
                tempData = data.filter(tempItem => (tempItem.vp_name == item.vp_name));
                cnt = tempData.length;
            } else if (vpValueLower != null && vpValueLower.includes(termLower)) {
                foundLevel = 3;
                // Count where vp_value are same
                tempData = data.filter(tempItem => (tempItem.vp_value == item.vp_value));
                cnt = tempData.length;
            } else if (vpSubValueLower != null && vpSubValueLower.includes(termLower)) {
                foundLevel = 4;
                // Count where vp_sub_value are same
                tempData = data.filter(tempItem => (tempItem.vp_sub_value == item.vp_sub_value));
                cnt = tempData.length;
            }

            return {
                ...item,
                foundLevel: foundLevel,
                cnt: cnt,
            };
        });

        setSearchData(data)
        setShowSearchMenu(true)
        setShowMenu(false)
        setSearchBlock2Data([])
        setSearchBlock3Data([])
        // // console.log('term:', term, 'featuresData:', data)

        setSearchTerm(term);
    }

    const onSearchBlock2insightSelected = (e, feature) => {
        e.stopPropagation();
        searchData.map(item => {
            if (item.vp_name == feature.vp_name) {
                if (e.target.checked) {
                    addToBlock2Selected(feature);
                } else {
                    removeFromBlock2Selected(feature)
                }
            }
            return item
        })
    }

    const onSearchBlock1MouseHover = (e, name, cnt, lvl) => {
        if (cnt > 1) {
            let tmpList = []
            searchData.map(feature => {
                if (feature.vp_category == name) {
                    let tmp = tmpList.find(t => t.vp_name == feature.vp_name)
                    if (tmp) {
                        if (feature.vp_value != null && feature.vp_value != "") {
                            tmp.cnt = tmp.cnt + 1
                        }
                    } else {
                        tmpList.push({ vp_name: feature.vp_name, cnt: 1, isChecked: false, isAllChecked: false, foundLevel: lvl })
                    }
                }
            })

            // // console.log('onSearchBlock1MouseHover', [...tmpList])
            setSearchBlock1Data([...tmpList])

        } else {
            setSearchBlock1Data([])
        }
        setSearchBlock2Data([])
    }

    const onSearchBlock2MouseHover = (e, name, cnt, lvl) => {
        if (cnt > 1) {
            let tmpList = []
            searchData.map(feature => {
                if (feature.vp_name == name) {
                    let tmp = tmpList.find(t => t.vp_value == feature.vp_value)
                    if (tmp) {
                        if (feature.vp_sub_value != null && feature.vp_sub_value != "") {
                            tmp.cnt = tmp.cnt + 1
                        }
                    } else {
                        if (feature.vp_value != null && feature.vp_value != "") {
                            tmpList.push({ vp_name: feature.vp_name, vp_value: feature.vp_value, cnt: 1, isChecked: false, isAllChecked: false, foundLevel: lvl })
                        }
                    }
                }
            })

            // // console.log('onSearchBlock2MouseHover', [...tmpList])
            setSearchBlock2Data([...tmpList])
            if (lvl == 2) {
                setSearchBlock1Data([])
            }

        } else {
            setSearchBlock2Data([])
        }
        setSearchBlock3Data([])
    }

    const onSearchBlock3MouseHover = (e, name, cnt, lvl) => {
        if (cnt > 1) {
            let tmpList = []
            searchData.map(feature => {
                if (feature.vp_value == name && (feature.vp_sub_value != null && feature.vp_sub_value != '')) {
                    let tmp = tmpList.find(t => t.vp_sub_value == feature.vp_sub_value)
                    if (tmp) {
                        if (feature.vp_sub_value != null && feature.vp_sub_value != "") {
                            tmp.cnt = tmp.cnt + 1
                        }
                    } else {
                        if (feature.vp_value != null && feature.vp_value != "") {
                            tmpList.push({ vp_name: feature.vp_name, vp_value: feature.vp_value, vp_sub_value: feature.vp_sub_value, cnt: 1, isChecked: false, isAllChecked: false, foundLevel: lvl })
                        }
                    }
                }
            })

            if (lvl == 3) {
                setSearchBlock1Data([])
                setSearchBlock2Data([])
            }
            // // console.log('onSearchBlock3MouseHover', [...tmpList])
            setSearchBlock3Data([...tmpList])

        } else {
            setSearchBlock3Data([])
        }
    }

    const onSearchBlock4MouseHover = () => {
        setSearchBlock1Data([])
        setSearchBlock2Data([])
        setSearchBlock3Data([])
    }

    const closeSearchShowMenu = (e) => {
        e.stopPropagation();
        setSearchData([])
        setShowSearchMenu(false)
        setShowMenu(!showMenu)
        setIsSearchMode(false);
        setSearchTerm('');
        setSearchBlock1Data([])
        setSearchBlock2Data([])
        setSearchBlock3Data([])
    }

    const searchBoxRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setSearchData([])
                setShowSearchMenu(false)
                setShowMenu(!showMenu)
                setIsSearchMode(false);
                setSearchTerm('');
                setSearchBlock1Data([])
                setSearchBlock2Data([])
                setSearchBlock3Data([])
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchBoxRef]);

    return (
        <div className="dropdown">
            <div className="d-grid gap-2">
                {isSearchMode ? (
                    <input
                        ref={searchInputRef}
                        type="text"
                        className="serach-box"
                        value={searchTerm}
                        onChange={(e) => searchTermUpdate(e.target.value)}
                        placeholder={placeholder}
                        onBlur={toggleSearchMode}
                    />
                ) : (
                    <button disabled={[17, 18, 20].includes(selectedInsightId) == false && (isInsightDropdownSelected) ? false : true} className={((isInsightDropdownSelected) ? '' : 'disabled-dropdown ') + "btn btn-lg text-dark txt-14px pr-10px btn-outline-secondary dropdown-toggle font-14p btn-w200p font-bold500"} onClick={e => toggleFeaturesDropdown(e)} type="button" >
                        {placeholder}
                    </button>
                )}
                {
                    (featuresNVehicleLoading && showMenu) ? (
                        <div className="container dropdown-menu show border-0 features-menu cst-top-position">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 mt-n5p">
                                        <div className="features-list" style={{ paddingLeft: '10px' }}>
                                            <List style={{ marginBottom: '10px', marginTop: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-n5p border-end">
                                        <div className="features-list" style={{ paddingLeft: '10px' }}>
                                            <List style={{ marginBottom: '10px', marginTop: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-n5p border-end">
                                        <div className="features-list" style={{ paddingLeft: '10px' }}>
                                            <List style={{ marginBottom: '10px', marginTop: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-n5p">
                                        <div className="features-list" style={{ paddingLeft: '10px' }}>
                                            <List style={{ marginBottom: '10px', marginTop: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                            <List style={{ marginBottom: '10px' }} width={380}></List>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    ) : showMenu && (
                        <div className="container dropdown-menu show border-0 features-menu cst-top-position">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3 mt-n5p">
                                        <div className="features-list" onClick={e => handleMainInsightClick(e)}>
                                            {
                                                featureCategories.map(option => (
                                                    <li key={option} onMouseEnter={e => handleCategorySelect(e, option)} className="dropdown-item lable" ><div onClick={e => handleMainInsightClick(e)}>{option}
                                                        <img src={arrowImg} alt="" className="float-end img-mt-7px " /></div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={"col-md-3 mt-n5p " + block2Classes}>
                                        <div className="features-list " onClick={e => handleMainInsightClick(e)}>
                                            {
                                                block2Data.map(feature => (
                                                    <li className="dropdown-item" key={feature.vp_name.split(" ").join("")} onMouseEnter={e => onBlock2MouseHover(e, feature.vp_name, feature.cnt)} >
                                                        <div className="form-check " >
                                                            <input className="form-check-input cursor" type="checkbox" onChange={e => onBlock2insightSelected(e, feature)}
                                                                value="" id={"flexCheckChecked" + feature.vp_name.split(" ").join("")} checked={checkIsBlock2Selected(feature)} />
                                                            <div onClick={e => handleMainInsightClick(e)} className="cursor" >
                                                                <label className="form-check-label text-black  w-90 cursor" htmlFor={"flexCheckChecked" + feature.vp_name.split(" ").join("")}>
                                                                    {feature.vp_name}
                                                                </label>
                                                                {
                                                                    feature.cnt > 0 &&  (
                                                                        <img src={arrowImg} alt='' className="float-end img-mt-7px "  />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className={"col-md-3 mt-n5p " + block3Classes}>
                                        <div className="features-list" onClick={e => handleMainInsightClick(e)}>
                                            {
                                                block3Data.map(feature => (
                                                    <li className="dropdown-item" key={feature.vp_value.split(" ").join("")} onMouseEnter={e => onBlock3MouseHover(e, feature.vp_name, feature.vp_value, feature.cnt)}  >
                                                        <div className="form-check cursor">
                                                            <input className="form-check-input cursor" type="checkbox" onChange={e => onBlock3insightSelected(e, feature)}
                                                                value="" id={"flexCheckChecked" + feature.vp_value.split(" ").join("")} checked={checkIsBlock3Selected(feature)} />
                                                            <div onClick={e => handleMainInsightClick(e)} className="cursor" >
                                                                <label className="form-check-label text-black w-90 cursor" htmlFor={"flexCheckChecked" + feature.vp_value.split(" ").join("")}>
                                                                    {feature.vp_value}
                                                                </label>
                                                                {
                                                                    feature.cnt > 0 && feature.vp_value != "Any" && (
                                                                        <img src={arrowImg} alt="" className="float-end img-mt-7px " />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-n5p">
                                        <div className="features-list" onClick={e => handleMainInsightClick(e)}>
                                            {
                                                block4Data.map(feature => {
                                                    if (feature.vp_sub_value != null && feature.vp_sub_value != "") {
                                                        return (
                                                            <li className="dropdown-item" key={feature.vp_sub_value.split(" ").join("")} >
                                                                <div className="form-check cursor">
                                                                    <input className="form-check-input cursor" type="checkbox" onChange={e => onBlock4insightSelected(e, feature)}
                                                                        value="" id={"flexCheckChecked" + feature.vp_sub_value.split(" ").join("")} checked={checkIsBlock4Selected(feature)} />
                                                                    <div onClick={e => handleMainInsightClick(e)} className="cursor" >
                                                                        <label className="form-check-label text-black w-90 cursor" htmlFor={"flexCheckChecked" + feature.vp_sub_value.split(" ").join("")}>
                                                                            {feature.vp_sub_value}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    showSearchMenu && (
                        <div className="dropdown-menu search-dropdown-menu" ref={searchBoxRef}>
                            <div className="row">
                                <div className="main col-md-3" style={{ overflowX: 'hidden' }}>
                                    <span style={{ color: 'white', position: 'absolute', zIndex: -1 }}>0</span>
                                    {
                                        searchData.map(feature => (
                                            feature.foundLevel == 1 && !alreadyAdded.includes(`lvl1_${feature.vp_category.split(" ").join("")}`) ? (
                                                (() => {
                                                    const thisKey = `lvl1_${feature.vp_category.split(" ").join("")}`;
                                                    alreadyAdded.push(thisKey);
                                                    return (
                                                        <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock1MouseHover(e, feature.vp_category, feature.cnt, feature.foundLevel)} >
                                                            <div className="item-box item_box_border" style={{ paddingLeft: '15px' }}>
                                                                <label className="parent-label">{feature.vp_category}</label>
                                                                <label className="container-checkbox">
                                                                    <div className="feature-name-crop">
                                                                        <span title={feature.vp_category}>{feature.vp_category}</span>
                                                                    </div>

                                                                    {feature.cnt > 1 && (
                                                                        <span className="pull-right" style={{ marginRight: '-15px' }}>
                                                                            <img src={arrowImg} className="pull-right" style={{ marginTop: '-18px', marginRight: '30px' }} />
                                                                        </span>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                })()
                                            ) : feature.foundLevel == 2 && !alreadyAdded.includes(`lvl2_${feature.vp_name.split(" ").join("")}`) ? (
                                                (() => {
                                                    const thisKey = `lvl2_${feature.vp_name.split(" ").join("")}`;
                                                    alreadyAdded.push(thisKey);
                                                    return (
                                                        <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock2MouseHover(e, feature.vp_name, feature.cnt, feature.foundLevel)} >
                                                            <div className="item-box item_box_border" style={{ paddingLeft: '15px' }}>
                                                                <label className="parent-label">{feature.vp_category}</label>
                                                                <label className="container-checkbox">
                                                                    <div className="feature-name-crop">
                                                                        <span title={feature.vp_name}>{feature.vp_name}</span>
                                                                    </div>
                                                                    <input type="checkbox" checked={checkIsBlock2Selected(feature)} onChange={(e) => onSearchBlock2insightSelected(e, feature)} />
                                                                    <span className="checkmark span-text"></span>
                                                                    {feature.cnt > 1 && (
                                                                        <span className="pull-right" style={{ marginRight: '-20px' }}>
                                                                            <img src={arrowImg} className="pull-right" style={{ marginTop: '-18px', marginRight: '30px' }} />
                                                                        </span>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                })()
                                            ) : feature.foundLevel == 3 && !alreadyAdded.includes(`lvl3_${feature.vp_name.split(" ").join("")}`) ? (
                                                (() => {
                                                    const thisKey = `lvl3_${feature.vp_name.split(" ").join("")}`;
                                                    alreadyAdded.push(thisKey);
                                                    return (
                                                        <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock3MouseHover(e, feature.vp_value, feature.cnt, feature.foundLevel)} >
                                                            <div className="item-box item_box_border" style={{ paddingLeft: '15px' }}>
                                                                <label className="parent-label">{feature.vp_name}</label>
                                                                <label className="container-checkbox">
                                                                    <div className="feature-name-crop">
                                                                        <span title={feature.vp_value}>{feature.vp_value}</span>
                                                                    </div>
                                                                    <input type="checkbox" checked={checkIsBlock3Selected(feature)} onChange={e => onBlock3insightSelected(e, feature)} />
                                                                    <span className="checkmark span-text"></span>
                                                                    {feature.cnt > 1 && (
                                                                        <span className="pull-right" style={{ marginRight: '-25px' }}>
                                                                            <img src={arrowImg} className="pull-right" style={{ marginTop: '-18px', marginRight: '30px' }} />
                                                                        </span>
                                                                    )}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                })()
                                            ) : feature.foundLevel == 4 && !alreadyAdded.includes(`lvl4_${feature.vp_name.split(" ").join("")}`) ? (
                                                (() => {
                                                    const thisKey = `lvl4_${feature.vp_name.split(" ").join("")}`;
                                                    alreadyAdded.push(thisKey);
                                                    return (
                                                        <li key={thisKey} className={thisKey} style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock4MouseHover()} >
                                                            <div className="item-box item_box_border" style={{ paddingLeft: '15px' }}>
                                                                <label className="parent-label">{feature.vp_value}</label>
                                                                <label className="container-checkbox">
                                                                    <div className="feature-name-crop">
                                                                        <span title={feature.vp_sub_value}>{feature.vp_sub_value}</span>
                                                                    </div>
                                                                    <input type="checkbox" checked={checkIsBlock4Selected(feature)} onChange={e => onBlock4insightSelected(e, feature)} />
                                                                    <span className="checkmark span-text"></span>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    );
                                                })()
                                            ) : null
                                        ))
                                    }


                                    <div
                                        style={{
                                            height: '40px',
                                            marginTop: '15px',
                                            color: 'rgb(16, 80, 255)',
                                            letterSpacing: '0.55px',
                                            fontSize: '14px',
                                            paddingLeft: '5px',
                                            cursor: 'pointer',
                                            fontWeight: 500
                                        }} onClick={e => closeSearchShowMenu(e)}>
                                        <span>&lt; back to list of features </span>
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    <ul className="sub-child">
                                        <div className="row">
                                            {
                                                searchBlock1Data.length > 0 && (
                                                    <div className="main col-md-4">
                                                        <ul className="sub-child">
                                                            <div className="row">
                                                                <div className="main col-md-12" style={{ overflowX: 'hidden' }}>
                                                                    <span style={{ color: 'white', position: 'absolute', zIndex: -1 }}>0</span>
                                                                    {
                                                                        searchBlock1Data.map(feature => (
                                                                            !alreadyAdded.includes(`lvl1_2_${feature.vp_name.split(" ").join("")}`) && (
                                                                                (() => {
                                                                                    const thisKey = `lvl1_2_${feature.vp_name.split(" ").join("")}`;
                                                                                    alreadyAdded.push(thisKey);
                                                                                    return (
                                                                                        <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock2MouseHover(e, feature.vp_name, feature.cnt, feature.foundLevel)} >
                                                                                            <div className="item-box" style={{ paddingLeft: '15px' }}>
                                                                                                <label className="container-checkbox">
                                                                                                    <div className="feature-name-crop">
                                                                                                        <span title={feature.vp_name}>{feature.vp_name}</span>
                                                                                                    </div>
                                                                                                    <input type="checkbox" checked={checkIsBlock2Selected(feature)} onChange={(e) => onSearchBlock2insightSelected(e, feature)} />
                                                                                                    <span className="checkmark span-text"></span>
                                                                                                    {feature.cnt > 1 && (
                                                                                                        <span className="pull-right" style={{ marginRight: '-25px' }}>
                                                                                                            <img src={arrowImg} className="pull-right" style={{ marginTop: '-18px', marginRight: '30px' }} />
                                                                                                        </span>
                                                                                                    )}
                                                                                                </label>
                                                                                            </div>
                                                                                        </li>
                                                                                    );
                                                                                })()
                                                                            )
                                                                        ))
                                                                    }
                                                                </div>
                                                                <div>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                            {
                                                searchBlock2Data.length > 0 && (
                                                    <div className="main col-md-4" style={{ overflowX: 'hidden' }}>
                                                        <span style={{ color: 'white', position: 'absolute', zIndex: -1 }}>0</span>
                                                        {
                                                            searchBlock2Data.map(feature => (
                                                                (feature.foundLevel == 1 || feature.foundLevel == 2) && !alreadyAdded.includes(`lvl2_2_${feature.vp_value.split(" ").join("")}`) ? (
                                                                    (() => {
                                                                        const thisKey = `lvl2_2_${feature.vp_value.split(" ").join("")}`;
                                                                        alreadyAdded.push(thisKey);
                                                                        return (
                                                                            <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }} onMouseEnter={(e) => onSearchBlock3MouseHover(e, feature.vp_value, feature.cnt, feature.foundLevel)} >
                                                                                <div className="item-box" style={{ paddingLeft: '15px' }}>
                                                                                    <label className="container-checkbox">
                                                                                        <div className="feature-name-crop">
                                                                                            <span title={feature.vp_value}>{feature.vp_value}</span>
                                                                                        </div>
                                                                                        <input type="checkbox" checked={checkIsBlock3Selected(feature)} onChange={e => onBlock3insightSelected(e, feature)} />
                                                                                        <span className="checkmark span-text"></span>
                                                                                        {feature.cnt > 1 && (
                                                                                            <span className="pull-right" style={{ marginRight: '-25px' }}>
                                                                                                <img src={arrowImg} className="pull-right" style={{ marginTop: '-18px', marginRight: '30px' }} />
                                                                                            </span>
                                                                                        )}
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })()
                                                                ) : feature.foundLevel == 3 && !alreadyAdded.includes(`lvl2_3_${feature.vp_sub_value.split(" ").join("")}`) && (
                                                                    (() => {
                                                                        const thisKey = `lvl2_3_${feature.vp_sub_value.split(" ").join("")}`;
                                                                        alreadyAdded.push(thisKey);
                                                                        return (
                                                                            <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                                                                                <div className="item-box" style={{ paddingLeft: '15px' }}>
                                                                                    <label className="container-checkbox">
                                                                                        <div className="feature-name-crop">
                                                                                            <span title={feature.vp_sub_value}>{feature.vp_sub_value}</span>
                                                                                        </div>
                                                                                        <input type="checkbox" checked={checkIsBlock4Selected(feature)} onChange={e => onBlock4insightSelected(e, feature)} />
                                                                                        <span className="checkmark span-text"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })()
                                                                )
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            <div className="main col-md-4">
                                                <ul className="sub-child">
                                                    <div className="row">
                                                        <div className="main col-md-12" style={{ overflowX: 'hidden' }}>
                                                            <span style={{ color: 'white', position: 'absolute', zIndex: -1 }}>0</span>
                                                            {
                                                                searchBlock3Data.map(feature => (
                                                                    !alreadyAdded.includes(`lvl2_3_${feature.vp_sub_value.split(" ").join("")}`) && (
                                                                        (() => {
                                                                            const thisKey = `lvl2_3_${feature.vp_sub_value.split(" ").join("")}`;
                                                                            alreadyAdded.push(thisKey);
                                                                            return (
                                                                                <li key={thisKey} className="" style={{ marginLeft: '-15px', marginRight: '-15px' }}>
                                                                                    <div className="item-box" style={{ paddingLeft: '15px' }}>
                                                                                        <label className="container-checkbox">
                                                                                            <div className="feature-name-crop">
                                                                                                <span title={feature.vp_sub_value}>{feature.vp_sub_value}</span>
                                                                                            </div>
                                                                                            <input type="checkbox" checked={checkIsBlock4Selected(feature)} onChange={e => onBlock4insightSelected(e, feature)} />
                                                                                            <span className="checkmark span-text"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        })()
                                                                    )
                                                                ))
                                                            }
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>

                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )

});

export default FeaturesDropdown;
