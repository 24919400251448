const { getOpenAxios } = require("../utils/apis")

const axios = getOpenAxios();

export const getInsightDataApi = async (data, id) => {
    if (id != 17 && id != 18 && id != 19) {
        return await axios.post("/insights/build", data)
    } else {
        if (id == 17 || id == 19) {
            return await axios.post("/insights/build", data, { responseType: 'arraybuffer' })
        } else {
            return await axios.post("/insights/build", data, { responseType: 'blob' })
        }

    }
}
