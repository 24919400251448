
export const processVehiclesData = (data) =>{
  //// console.log("processVehiclesData ", data)
    return [
        {
          category: "Model Year",
          values: [...data.year.filter( ele => ele !="" )]
        },
        {
          category: "Brand Type",
          values: [...data.class.filter( ele => ele !="" )]
        },
        {
          category: "Brand",
          values: [...data.make.filter( ele => ele !="" )]
        },
        {
          category: "Vehicle Type",
          values: [...data.vehicle_type.filter( ele => ele !="" )]
        },
        {
          category: "Segments",
          values: [...data.segment_name.filter( ele => ele !="" )]
        },
        {
          category: "Model",
          values: [...data.model.filter( ele => ele !="" )]
        },
        {
          category: "Trim",
          values: [...data.trims.filter( ele => ele !="" )]
        }
      ]
}