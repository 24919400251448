import { useRef, useState, useEffect } from "react"

import DeepDive from "../DeepDives/DeepDive";
import pencilImg from "../assets/img/pencil.png"
import advBlueImg from "../assets/img/adv_config_blue.png"
import close_xImg from "../assets/img/close_x.png"
import closeImg from "../assets/img/close.png"

import filter_arrow_upImg from "../assets/img/filter_arrow_up.png"
import filter_arrow_downImg from "../assets/img/filter_arrow_down.png"

import AdvancedFiltersModalComponent from "../ModalComponents/AdvancedFiltersModalComponent";

const ResultTable = ({ features, insight, vehicles, headerFeatures, headersWithValues, resultData,
    vehKeys, featureIds, tblVehicles, processedInsight, insightTitle, handleShowSaveModal, sortResultTable, filterResultTable, filterBasedOnCheckBoxes, resultTableRef, vehicleDropdownData, selectedInsightId }) => {

    // // console.log('selectedInsightId:- ', selectedInsightId, 'insight:- ', insight)
    //"featureIds ", featureIds, resultData)
    //const [headerFeatures, setHeaderFeatures] = useState([])
    // vp_category, vp_name, vp_value, vp_sub_value, sbd_feature_id

    var totalLength = 0;
    if (headerFeatures.length && tblVehicles.length) {
        totalLength = tblVehicles.length + headerFeatures[0].cnt;
    }
    // Columns style
    const firstColumnHeaderStyle = {
        border: '1px solid #000',
        width: 'calc(30%/' + totalLength + ')'
    };

    const secondColumnHeaderStyle = {
        border: '1px solid #000',
        width: 'calc(70%/' + totalLength + ')'
    };

    const [showDeepDive, setShowDeepDive] = useState(false)


    const getCellvalue = (data, key) => {
        let d = data.data.filter(ele => ele.id == key)
        if (processedInsight == "Fitment Type") {
            if (d.length > 1) {
                let lst = d.map(el => el.value)
                if (lst.includes("Standard") && lst.includes("Optional")) {

                    return "Mixed"
                }
                if (lst.includes("Standard")) {
                    return "Standard"
                }
                if (lst.includes("Optional")) {
                    return "Optional"
                }
                return "N/A"
            }
            if (d.length == 1) {
                return d[0].value
            }
            return ""
        }
        if (processedInsight == "Yes / No") {
            if (d.length > 1) {
                let lst = d.map(el => el.value)
                if (lst.includes("Y") && lst.includes("N")) {
                    return "Y"
                }
                if (lst.includes("Y")) {
                    return "Y"
                }
                if (lst.includes("N")) {
                    return "N"
                }
                return "N/A"
            }
            if (d.length == 1) {
                return d[0].value
            }
            return ""
        }
        if (processedInsight == "Percentage of Models" || processedInsight == "Percentage of trims") {
            let d = data.data.filter(ele => ele.id == key)
            // // console.log('processedInsight: ', processedInsight)
            if (d.length == 0) {
                return '0%'
            }
            return d[0].value != undefined ? d[0].value : "0%"
        }
        if (processedInsight == "By Feature") {
            //// console.log("By Feature ", data, key)
            let d = data.data.filter(ele => ele.id == key)
            if (d.length == 0) {
                return '0%'
            }
            //// console.log(data, d, key)
            // return d[0].value != undefined ? formatValue(parseFloat(d[0].value)) + "%" : "0%"            
            return d[0].value != undefined ? d[0].value : "0%"
        }

        if (processedInsight == "Minimum" || processedInsight == "Maximum" || processedInsight == "Average") {
            //// console.log("Minimum ", data, key)
            let d = data.data.filter(ele => ele.id == key)
            if (d.length == 0) {
                return 'N/A'
            }
            if (isNaN(d[0].value)) {
                return 'N/A'
            } else {
                if (d[0].value == '0') {
                    return 'N/A'
                } else {
                    return parseFloat(d[0].value).toFixed(2)
                }
            }

        }
        if (processedInsight == "Maximum MSRP" || processedInsight == "Minimum MSRP") {
            // // console.log("d", d)
            if (d.length > 1) {
                let lst = d.map(el => el.value)
                let non_na = lst.filter(e => e.includes("na_") == false)
                // // console.log('non_na', non_na)
                if (non_na.length == 0) {
                    return "N/A"
                } else {
                    return "$" + addCommasToNumber(non_na[0].split("_")[1].toLocaleString())
                }
            }
            if (d.length == 1) {
                let tmpLst = d[0].value.split("_")
                if (tmpLst[0] == "na") {
                    return "N/A"
                } else {
                    return "$" + addCommasToNumber(tmpLst[1].toLocaleString())
                }
            } else {
                return ""
            }
        }
        if (processedInsight == "Minimum Package Pricing") {
            // // console.log("d", d)
            if (d.length > 1) {
                let lst = d.map(el => el.value)
                let opt = lst.filter(e => e.includes("opt_") == true)
                let std = lst.filter(e => e.includes("std_") == true)
                //// console.log('opt', opt)
                if (opt.length == 0) {
                    return std.length == 0 ? 'N/A' : 'Standard'
                } else {
                    return "$" + addCommasToNumber(opt[0].split("_")[1].toLocaleString())
                }
            }
            if (d.length == 1) {
                let tmpLst = d[0].value.split("_")
                if (tmpLst[0] == "na" || tmpLst[0] == "std") {
                    return tmpLst[0] == "na" ? 'N/A' : 'Standard'
                } else {
                    return "$" + addCommasToNumber(tmpLst[1].toLocaleString())
                }
            } else {
                return ""
            }
        }
    }


    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const formatValue = (value) => {
        //// console.log(value)
        if (value > 1) {
            value = 1.0
        }
        let s = value > 0 ? (value.toFixed(2) * 100) : (value * 100)
        //// console.log('formatValue', value, " : ", s)
        return parseInt(s.toString());
    }

    /*
        Percentage of models offered with Brakes across MY24 vehicles
        Percentage of models offered with Brakes across MY16 - MY24 vehicles
    
        Percentage of models offered with Brakes across 3 brand classes -> all classes
        Percentage of models offered with Brakes across Mainstream and Premium vehicles -> few classes
    
        Percentage of models offered with Brakes across Alfa Romeo and Audi vehicles
        Percentage of models offered with Brakes across 15 brands 
    
        // features
        Percentage of models offered with 49 features across
    
    */

    const [deepdiveFeatureData, setDeepdiveFeatureData] = useState({})
    const [deepdiveData, setDeepdiveData] = useState({})
    const [deepdiveCellValue, setDeepdiveCellValue] = useState("")

    const toggleDeepDive = (value, data, id, cellValue, e) => {
        e.preventDefault();
        let feature = headersWithValues.filter(e => e.id == id)
        //// console.log("deep dive data ", processedInsight, id, data, feature, headersWithValues)
        setDeepdiveFeatureData({ ...feature[0] })
        setDeepdiveData({ ...data })
        setShowDeepDive(value)
        setDeepdiveCellValue(cellValue)
    }

    // State to track which cell is clicked
    const [selectedCell, setSelectedCell] = useState(null);

    // Reference to the popup element
    const filter_popupRef = useRef(null);

    // Close the popup when a click occurs outside of it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (filter_popupRef.current && !filter_popupRef.current.contains(event.target)) {
                setSelectedCell(null);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };

        // Get the width of the trim-col-td
        const trimColumn = document.querySelector('.trim-col-td');
        if (trimColumn) {
            const columnWidth = trimColumn.offsetWidth;
            // console.log('Width of the trim-col-td:', columnWidth);
        }

    }, []);

    // Function to toggle the selected cell
    const togglePopup = (columnIndex) => {
        if (selectedCell && selectedCell.column == columnIndex) {
            setSelectedCell(null);
        } else {
            setSelectedCell({ column: columnIndex });
        }
    };

    // States for the three inputs
    const [showAbove, setShowAbove] = useState('');
    const [showBelow, setShowBelow] = useState('');
    const [showOnly, setShowOnly] = useState('');

    // table Scroll
    const table2Ref = useRef(null);
    const table3Ref = useRef(null);
    const table4Ref = useRef(null);

    const handletable4Scroll = () => {
        // Capture the vertical scroll position of div4
        const scrollTop = table4Ref.current.scrollTop;
        const scrollLeft = table4Ref.current.scrollLeft;

        // Apply the same scroll position to div3
        // Assuming div3 has the ref div3Ref
        table2Ref.current.scrollLeft = scrollLeft;
        table3Ref.current.scrollTop = scrollTop;

        //// console.log('Vertical Scroll:', scrollTop);
        //// console.log('Horizontal Scroll:', scrollLeft);
    };

    var defaultRows = 15;

    var leftColumnHeaderCount = (tblVehicles.length > 0) ? 0 : 1;

    var leftColumnsCount = (tblVehicles.length > 0) ? tblVehicles.length : 1;
    var rightColumnsCount = headersWithValues.length;

    if (resultData.length) {
        defaultRows = (defaultRows < resultData.length) ? 0 : defaultRows - resultData.length
    }

    const [filterCheckBoxes, setFilterCheckBoxes] = useState({
        opt: false, std: false, na: false, mixed: false, yes: false, no: false
    })

    const handleFilterCheckboxes = (key, e, eid) =>{
        let tmp = {...filterCheckBoxes}
        tmp[key] = e.target.checked
        setFilterCheckBoxes({...tmp})
        filterBasedOnCheckBoxes(eid, {...tmp})
    }

    const removeFilterCheckBoxes = () =>{
        setFilterCheckBoxes({ opt: false, std: false, na: false, mixed: false, yes: false, no: false})
        filterBasedOnCheckBoxes(0, { opt: false, std: false, na: false, mixed: false, yes: false, no: false})
    }

    return (
        <div >
            <div className="col-12">
                <div className="card mt-4 mb-5 border-0 " >
                    <div className="card-body  " style={{ minHeight: "70.5vh" }}>
                        <div className="row">
                            {/* Insight title */}
                            <div className="col-md-6 mb-2">
                                {
                                    (insightTitle !== "" && (headersWithValues.length > 0 || leftColumnsCount > 1) && (selectedInsightId != 17 && selectedInsightId != 19)) && (
                                        <h6 className="h6-insight-name"><strong>{insightTitle}</strong>
                                            <button type="button" data-toggle="modal" className="btn btn-primary button-icons-active save-insight-btn" onClick={handleShowSaveModal}>
                                                <img src={pencilImg} className="pencilImg" alt="Save Insight" />
                                            </button>
                                        </h6>
                                    )
                                }
                            </div>

                            {/* Advanced filter */}
                            <div className="col-md-6 text-end mb-2">
                                {/* {
                                    resultData.length > 0 && ( */}
                                <button type="button" data-toggle="modal" className="btn btn-primary button-icons-active" data-bs-toggle="modal" data-bs-target="#advancedFiltersModal" disabled>
                                    <span className="adv-filter-btn-span button-text-inactive">
                                        <img src={advBlueImg} className="adv-blue-img hide-tab-div" alt="Advanced filters" />
                                        Advanced filters
                                    </span>
                                </button>

                            </div>
                            {/* Table Start */}
                            {
                                ((resultData.length == 0 && headersWithValues.length == 0 && tblVehicles.length < 1) || (selectedInsightId == 17 || selectedInsightId == 19)) && (
                                    <div>
                                        <center><h4 className="text-secondary">Please build an Insight </h4></center>
                                    </div>
                                )
                            }
                            {
                                ((headersWithValues.length > 0 || leftColumnsCount > 1) && (selectedInsightId != 17 && selectedInsightId != 19)) && (
                                    <div className="table-div">
                                        <div className="table-container div-relative">
                                            <div className="div-table-split div-border-top">
                                                {/* Top div left part */}
                                                <div className="div-table-split-left div-border-right" style={{ maxWidth: `${170 * leftColumnsCount}px`, width: `100%` }}>
                                                    <div className="outer-header-span">
                                                        <span colSpan="1" className="header-top-col"></span>
                                                    </div>
                                                    <div>
                                                        {
                                                            tblVehicles.map((veh, i) => (
                                                                <div id={"bc" + i} className="outer-header-top outer-header-fix " key={veh.category.split(" ").join("")}>
                                                                    <div title={veh.category} className="inner-header-top border_none">
                                                                        {veh.category}
                                                                    </div>
                                                                    <img src={close_xImg} id={"thd" + i} data-toggle="tooltip" title="Remove" className="pull-right inner-header-img hide-tab-div" />
                                                                </div>
                                                            ))
                                                        }
                                                        {[...Array(leftColumnHeaderCount)].map((_, j) => (
                                                            <div key={j} className="outer-header-top outer-header-fix">
                                                                <div className="inner-header-top border_none">
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                {/* Top div right part */}
                                                <div className="div-table-split-right right-height header-top-right scroll-top height-86" ref={table2Ref}>
                                                    <div className="header-box-row">
                                                        {
                                                            headerFeatures.map(ele => (
                                                                <div colSpan={ele.cnt} title={ele.title} className="header-box-div" style={{ width: `${170 * ele.cnt}px` }} key={ele.title.split(" ").join("")}>
                                                                    <div title={ele.title} className="header-box-content header-box-width">
                                                                        {ele.title}
                                                                    </div>
                                                                    <div className="border-right-style">

                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                        <div className="header-box-div" style={{ width: `170px` }}>
                                                            <div className="header-box-content">

                                                            </div>
                                                            <div className="border-right-style">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            {
                                                                headersWithValues.map((ele, i) => (
                                                                    <div id="btr0" className="row_column_hover outer-header" key={ele.id} onClick={() => togglePopup(i)} >
                                                                        <div className="border-right-style position-top">
                                                                            <div id="txt0" title="Any" className="inner-header">
                                                                                {ele.value}
                                                                            </div>
                                                                            <img src={closeImg} id="thr0" className="pull-right inner-header-icon hide-tab-div" />
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            <div className="row_column_hover outer-header">
                                                                <div className="border-right-style position-top">
                                                                    <div className="inner-header">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="result_bottom_main_div" className="div-table-split bottom-div">
                                                <div id="result_bottom_left_table" className="div-table-split-left div-border-right" style={{ maxWidth: `${170 * leftColumnsCount}px`, width: `100%`, overflowX: `scroll` }} ref={table3Ref}>
                                                    {/* Bottom div left part */}
                                                    <table className="table table-bordered right-table bottom-div-left-table">
                                                        <tbody>
                                                            {
                                                                resultData.map((data, i) => (
                                                                    <tr key={i} >
                                                                        {
                                                                            vehKeys.map((key, j) => (
                                                                                <td key={j} className={(key == 'Trim') ? "trim-col-td" : ''}>
                                                                                    <span data-toggle="tooltip" title={data[key]} className="col-padding">{data[key]}</span>
                                                                                </td>
                                                                            ))
                                                                        }
                                                                        {
                                                                            featureIds.map((id, k) => (
                                                                                <td key={k} className="hide-tab-div">
                                                                                    <span data-toggle="tooltip" title={getCellvalue(data, id)} className="col-padding">{getCellvalue(data, id)}</span>
                                                                                </td>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                ))
                                                            }

                                                            {[...Array(defaultRows)].map((_, i) => (
                                                                <tr key={i}>
                                                                    {[...Array(leftColumnsCount)].map((_, j) => (
                                                                        <td key={j} className="text_center">
                                                                            <span className="col-padding">-</span>
                                                                        </td>
                                                                    ))}
                                                                    {[...Array(rightColumnsCount)].map((_, k) => (
                                                                        <td key={k} className=" hide-tab-div">
                                                                            <span className="col-padding">-</span>
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div id="result_bottom_div" className="div-table-split-right bottom-div-right scroll-bottom" onScroll={handletable4Scroll} ref={table4Ref}>
                                                    {/* Bottom div right part */}
                                                    <table className="table table-bordered bottom-div-table">
                                                        <tbody>
                                                            <tr className="fixed-height">
                                                                {
                                                                    headersWithValues.map((ele, i) => (
                                                                        <td id={"td" + i} className="col-filter">
                                                                            <div className={selectedCell && selectedCell.column == i ? "show-filter" : " hide-tab-div"}>
                                                                                <div className="row filter-dropdown">
                                                                                    {
                                                                                        ["Fitment Type","Yes / No"].includes(processedInsight) && 
                                                                                        <div>
                                                                                            <span className="sort-by-low-span" onClick={() => {
                                                                                            sortResultTable('asc', ele.id);
                                                                                            togglePopup(i);
                                                                                            }}>
                                                                                                <img src={filter_arrow_upImg} className="sortby-img" />
                                                                                                Sort from A-Z
                                                                                            </span>
                                                                                            <span className="sort-by-up-span" onClick={() => { sortResultTable('dsc', ele.id); togglePopup(i); }}>
                                                                                                <img src={filter_arrow_downImg} className="sortby-img" />
                                                                                                Sort from Z-A
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        ["Fitment Type","Yes / No"].includes(processedInsight) == false && 
                                                                                        <div>
                                                                                            <span className="sort-by-low-span" onClick={() => {
                                                                                            sortResultTable('lowest', ele.id);
                                                                                            togglePopup(i);
                                                                                            }}>
                                                                                            <img src={filter_arrow_upImg} className="sortby-img" />
                                                                                            Sort by lowest
                                                                                            </span>
                                                                                            <span className="sort-by-up-span" onClick={() => { sortResultTable('highest', ele.id); togglePopup(i); }}>
                                                                                                <img src={filter_arrow_downImg} className="sortby-img" />
                                                                                                Sort by highest
                                                                                            </span>
                                                                                        </div>
                                                                                    }
                                                                                    

                                                                                    <hr className="sortby-hr-one" />
                                                                                    {(processedInsight == "Fitment Type") && (
                                                                                        <div>
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedMixed"
                                                                                                 value={filterCheckBoxes.mixed} onChange={e => handleFilterCheckboxes('mixed', e, ele.id) } />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedMixed">Mixed</label>
                                                                                            </div>
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedNA" 
                                                                                                value={filterCheckBoxes.na} onChange={e => handleFilterCheckboxes('na', e, ele.id)}  />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedNA">N/A</label>
                                                                                            </div>
                                                                                            
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedOpt" 
                                                                                                value={filterCheckBoxes.opt} onChange={e => handleFilterCheckboxes('opt', e, ele.id)}  />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedOpt">Optional</label>
                                                                                            </div>
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedStandard"
                                                                                                 value={filterCheckBoxes.std} onChange={e => handleFilterCheckboxes('std', e, ele.id)} />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedStandard">Standard</label>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    )}
                                                                                    {(processedInsight == "Yes / No") && (
                                                                                        <div>
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedY" 
                                                                                                value={filterCheckBoxes.yes} onChange={e => handleFilterCheckboxes('yes', e, ele.id)}  />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedY">Y</label>
                                                                                            </div>
                                                                                            <div className="form-check ">
                                                                                                <input className="form-check-input cursor filter-checkbox" type="checkbox" id="flexCheckCheckedN" 
                                                                                                value={filterCheckBoxes.no} onChange={e => handleFilterCheckboxes('no', e, ele.id)}  />
                                                                                                <label className="form-check-label w-100 cursor text-black filter-checkbox-label" for="flexCheckCheckedN">N</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                    {
                                                                                        ["Fitment Type","Yes / No"].includes(processedInsight) && 
                                                                                        <div>
                                                                                            <hr className="sortby-hr-two" />
                                                                                            <span className="sortby-remove-span" onClick={ removeFilterCheckBoxes }>Remove</span>
                                                                                        </div>
                                                                                    }
                                                                                    
                                                                                    {(processedInsight == "Percentage of Models" || processedInsight == "Percentage of trims" || processedInsight == "By Feature") && (
                                                                                        <div>
                                                                                            <label className="container-label show-above-label">Show above (%)</label>
                                                                                            <input type="text" value={showAbove} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                // Update Show state
                                                                                                setShowAbove(newValue);
                                                                                                setShowBelow('');
                                                                                                setShowOnly('');
                                                                                                filterResultTable('greater', ele.id, newValue)
                                                                                            }} />
                                                                                            <label className="container-label">Show below (%)</label>
                                                                                            <input type="text" value={showBelow} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                setShowAbove('');
                                                                                                setShowBelow(newValue);
                                                                                                setShowOnly('');
                                                                                                filterResultTable('less', ele.id, newValue)
                                                                                            }} />
                                                                                            <label className="container-label">Show only (%)</label>
                                                                                            <input type="text" value={showOnly} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                setShowAbove('');
                                                                                                setShowBelow('');
                                                                                                setShowOnly(newValue);
                                                                                                filterResultTable('equal', ele.id, newValue)
                                                                                            }} />
                                                                                        </div>
                                                                                    )}
                                                                                    {['Maximum MSRP', 'Minimum MSRP', 'Minimum Package Pricing', 'Average', 'Maximum', 'Minimum'].includes(processedInsight) && (
                                                                                        <div>
                                                                                            <label className="container-label show-above-label">Show above ($)</label>
                                                                                            <input type="text" value={showAbove} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                // Update Show state
                                                                                                setShowAbove(newValue);
                                                                                                setShowBelow('');
                                                                                                setShowOnly('');
                                                                                                filterResultTable('greater', ele.id, newValue)
                                                                                            }} />
                                                                                            <label className="container-label">Show below ($)</label>
                                                                                            <input type="text" value={showBelow} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                setShowAbove('');
                                                                                                setShowBelow(newValue);
                                                                                                setShowOnly('');
                                                                                                filterResultTable('less', ele.id, newValue)
                                                                                            }} />
                                                                                            <label className="container-label">Show only ($)</label>
                                                                                            <input type="text" value={showOnly} className="form-control form-control-sortby m-0" onChange={(event) => {
                                                                                                let newValue = event.target.value;
                                                                                                setShowAbove('');
                                                                                                setShowBelow('');
                                                                                                setShowOnly(newValue);
                                                                                                filterResultTable('equal', ele.id, newValue)
                                                                                            }} />
                                                                                        </div>
                                                                                    )}
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    ))
                                                                }

                                                                <td className="div-top-border col-filter">
                                                                </td>
                                                            </tr>
                                                            {
                                                                resultData.map((data, i) => (
                                                                    <tr key={i} >
                                                                        {
                                                                            vehKeys.map((key, j) => (
                                                                                <td key={j} className="fixed-width fixed-width-values hide-tab-div">
                                                                                    <span data-toggle="tooltip" title={data[key]} className="col-padding">{data[key]}</span>
                                                                                </td>
                                                                            ))
                                                                        }
                                                                        {
                                                                            featureIds.map((id, k) => (
                                                                                <td key={k} className="fixed-width fixed-width-values" onClick={e => toggleDeepDive(true, data, id, getCellvalue(data, id), e)}>
                                                                                    <span data-toggle="tooltip" title={getCellvalue(data, id)} className="col-padding">{getCellvalue(data, id)}</span>
                                                                                </td>
                                                                            ))
                                                                        }
                                                                        <td className="div-top-border">
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }

                                                            {[...Array(defaultRows)].map((_, i) => (
                                                                <tr key={i}>
                                                                    {[...Array(leftColumnsCount)].map((_, j) => (
                                                                        <td key={j} className="fixed-width hide-tab-div">
                                                                            <span className="fixed-padding">-</span>
                                                                        </td>
                                                                    ))}
                                                                    {[...Array(rightColumnsCount)].map((_, k) => (
                                                                        <td key={k} className="fixed-width text_center">
                                                                            <span className="fixed-padding">-</span>
                                                                        </td>
                                                                    ))}
                                                                    <td className="div-top-border">
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {/* Table END */}
                            <div className="col-md-12 sbd-result-table hide-tab-div">
                                {/* {
                                    (resultData.length == 0 && headersWithValues.length == 0) && (
                                        <div>
                                            <center><h4 className="text-secondary">Please build an Insight</h4></center>
                                        </div>
                                    )
                                } */}
                                <div className="">
                                    <table className="table table-hover table-bordered" ref={resultTableRef}>
                                        <thead>
                                            <tr>
                                                {tblVehicles.length > 0 && <td colSpan={tblVehicles.length > 0 ? tblVehicles.length : 1} style={firstColumnHeaderStyle} className="frozen-horizontal"></td>}
                                                {
                                                    headerFeatures.map(ele => (
                                                        <td colSpan={ele.cnt} key={ele.title.split(" ").join("")} className="font-14p frozen-vertical" style={secondColumnHeaderStyle}>{ele.title}</td>
                                                    ))
                                                }
                                            </tr>
                                            <tr>
                                                {
                                                    tblVehicles.map((veh, i) => (
                                                        <td key={veh.category.split(" ").join("")} className={(i != tblVehicles.length - 1 ? '' : 'sbd-border-end ') + (i == 0 ? 'sbd-border-start ' : '') + 'sbd-border-top sbd-border-bottom frozen-horizontal'}>
                                                            {veh.category}
                                                            {/* <img src={close_xImg} className="pull-right inner-header-img" alt="remove column" /> */}
                                                        </td>
                                                    ))
                                                }
                                                {
                                                    headersWithValues.map((ele, i) => (
                                                        <td key={ele.id} className={(i != headersWithValues.length - 1 ? '' : 'sbd-border-end ') + (i == 0 ? 'sbd-border-start ' : '') + 'sbd-border-top sbd-border-bottom row_column_hover '}>
                                                            {ele.value}
                                                            {/* <img src={closeImg} className="pull-right inner-header-icon" alt="sort column" onClick={() => togglePopup(i)} /> */}
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                resultData.map((data, i) => (
                                                    <tr key={i} >
                                                        {
                                                            vehKeys.map((key, j) => (
                                                                <td key={j} className={(j != vehKeys.length - 1 ? '' : 'sbd-border-end ') + (j == 0 ? 'sbd-border-start ' : '') + (i == resultData.length - 1 ? 'sbd-border-bottom ' : '') + 'font-bold500 frozen-horizontal'}>{data[key]}</td>
                                                            ))
                                                        }
                                                        {
                                                            featureIds.map((id, k) => (
                                                                <td key={k} className={(k != featureIds.length - 1 ? '' : 'sbd-border-end ') + (k == 0 ? 'sbd-border-start ' : '') + (i == resultData.length - 1 ? 'sbd-border-bottom ' : '') + ' cursor result-value-td font-bold500'} >{getCellvalue(data, id)}</td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showDeepDive &&
                <div className="row deep-dive-container">
                    <div className="col-md-8" onClick={e => setShowDeepDive(false)}></div>
                    <div className="col-md-4">
                        <div className="deep-dive shadow">
                            <DeepDive featureData={deepdiveFeatureData} headersWithValues={headersWithValues} vehicleData={deepdiveData} insight={processedInsight} cellValue={deepdiveCellValue} hideDeepdive={setShowDeepDive} />
                        </div>
                    </div>
                </div>
            }

            <AdvancedFiltersModalComponent vehicleDropdownData={vehicleDropdownData} />
        </div>
    )
}

export default ResultTable