export const actionTypes ={
    INSIGHT_SELECTED: 'INSIGHT_SELECTED',
    INSIGHT_SELECTED_FEATURES_UPDATED: 'INSIGHT_SELECTED_FEATURES_UPDATED',
    INSIGHT_SELECTED_VEHICLES_UPDATED: 'INSIGHT_SELECTED_VEHICLES_UPDATED',
    UPDATE_CATEGORIES_FROM_API: 'UPDATE_CATEGORIES_FROM_API',
    UPDATE_FEATURES_FROM_API: 'UPDATE_FEATURES_FROM_API',
    UPDATE_VEHICLES_FROM_API: 'UPDATE_VEHICLES_FROM_API',
}

export const updateCategoriesFromApi =  (payload) => {
	return { type: actionTypes.UPDATE_CATEGORIES_FROM_API, payload: payload };
};

export const updateFeaturesFromApi =  (payload) => {
	return { type: actionTypes.UPDATE_FEATURES_FROM_API, payload: payload };
};

export const updateVehiclesFromApi =  (payload) => {
	return { type: actionTypes.UPDATE_VEHICLES_FROM_API, payload: payload };
};

export const saveSelectedInsight =  (payload) => {
	return { type: actionTypes.INSIGHT_SELECTED, payload: payload };
};

export const updateSelectedFeatures =  (payload) => {
	return { type: actionTypes.INSIGHT_SELECTED_FEATURES_UPDATED, payload: payload };
};

export const updateSelectedVehicles =  (payload) => {
	return { type: actionTypes.INSIGHT_SELECTED_VEHICLES_UPDATED, payload: payload };
};
