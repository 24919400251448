import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { responses } from '../utils/responses'
import { saveInsightDataApi } from '../apis/insightAction';
import cookie from 'react-cookies'

import afterSaveImg from "../assets/img/after_save.png";

const SaveInsightComponent = forwardRef((props, ref) => {
    const { onShow, insightTitle, insightPayloadData, setInsightPayloadData, setIsFavorite, handleOuterComponentClick } = props;

    const [showModal, setShowModal] = useState(false);
    let insightName = insightTitle;

    useImperativeHandle(ref, () => ({
        showModal() {
            // You can perform actions before showing the modal
            onShow && onShow();

            setInsightPayloadData((prevData) => ({
                ...prevData,
                name: insightName,
            }));

            // Then show the modal
            setShowModal(true);

        }, storeInsightData
    }));

    // Save insight segment

    const handleInputChange = (event) => {
        insightName = event.target.value;
        setInsightPayloadData((prevData) => ({
            ...prevData,
            name: insightName,
        }));
    };
    useEffect(() => {
        // // console.log('insightPayloadData 2', insightPayloadData); // This will reflect the updated state
    }, [insightPayloadData]);

    const storeInsightData = async () => {
        setInsightPayloadData((prevData) => ({
            ...prevData,
            name: insightName,
            is_favorite: 1,
        }));

        if (Object.keys(insightPayloadData).length > 0) {

            // Wait for setInsightPayloadData to complete
            setInsightPayloadData(prevData => {
                // Make the API call after setting the state
                try {
                    saveInsightDataApi({prevData, user_id: cookie.load('user_id') })
                        .then((response) => {
                            if (response.data.result == responses.RESULT_OK) {
                                localStorage.setItem('favInsightId', response.data.data.id);
                                handleOuterComponentClick();

                                setIsFavorite(true);
                                setShowModal(false);

                            } else {
                                //// console.log('false:', response.data.msg);
                            }
                        })
                        .catch((error) => {
                            //// console.log('catch:', error.message);
                        });
                } catch (error) {
                    //// console.log('catch:', error.message);
                }

                return prevData;
            });
        } else {
            // console.log('insightPayloadData length is 0 : ', insightPayloadData);
        }
    };

    const dialogClassName = 'modal-dialog-mt-15'
    return (
        <div>

            {/* Modal */}
            <Modal show={showModal} dialogClassName={dialogClassName} onHide={() => setShowModal(false)} onShow={onShow}>
                <Modal.Body>
                    <p className='save-popup-heading mb-1'>
                        <img src={afterSaveImg} className="button-img save-insight-img" alt='save insight' />
                        Save your build
                    </p>
                    <div className="col-md-12">
                        <input type="text" className="form-control save-popup-textbox" defaultValue={insightTitle} onChange={handleInputChange} />
                    </div>
                    <span className="hint-span">
                        Hint: Make sure the title is recognizable so it can be found easily next time.
                    </span>
                    <div className='row footer-border'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4'>
                            <button type="button" className="btn btn-link" onClick={() => setShowModal(false)}>Cancel</button>
                            <button type="button" className="btn btn-link" onClick={storeInsightData}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
});

export default SaveInsightComponent;