import React from 'react';

import afterSaveImg from "../assets/img/after_save.png";

const ModalComponent = React.forwardRef((props, ref) => {
    //  ({ insightTitle, handleShowSaveModal, resetAll }) => {
    const { insightTitle, handleShowSaveModal, resetAll } = props;

    const handleClick = () => {
        // Your click event logic here
        //// console.log('InnerComponent Clicked!');
    };

    // Expose the handleClick function via the ref
    React.useImperativeHandle(ref, () => ({
        handleClick,
    }));

    return (
        <div>
            {/* New insight popup */}
            <div className="modal fade" id="newInsightModal" tabIndex="-1" aria-labelledby="newInsightModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-mt-15">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className='newInsightPopup-title'>
                                Starting a new build
                            </p>
                            <p className='newInsightPopup-font-size-14'>
                                This will remove all of your input so far. If you don't want
                                to lose what you've built, press save and come back to it
                                later.
                            </p>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <button type="button" className="btn btn-link" id="newInsightModal_close" data-bs-dismiss="modal" onClick={handleClick}>Cancel</button>
                                </div>
                                <div className='col-md-5'>
                                    <button type="button" className="btn btn-link" data-bs-dismiss="modal" onClick={resetAll}>Start New</button>
                                    <button type="button" className="btn btn-link" onClick={handleShowSaveModal}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Export popup - insight builder section */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-mt-15">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className='newInsightPopup-title'>
                                File being prepared
                            </p>
                            <p className='newInsightPopup-font-size-14'>
                                Our engine is pulling your data together, ready to place into an excel file.
                            </p>
                            <div className='row pull-right'>
                                <div className='col-md-7'>
                                    <button type="button" className="btn btn-link" data-bs-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Save insight popup - Result section */}
            <div className="modal fade" id="saveInsightModal" tabIndex="-1" aria-labelledby="saveInsightModal" aria-hidden="true">
                <div className="modal-dialog modal-dialog-mt-15">
                    <div className="modal-content">

                        <div className="modal-body">
                            <p className='save-popup-heading mb-1'>
                                <img src={afterSaveImg} className="button-img save-insight-img" alt='save insight' />
                                Save your build
                            </p>
                            <div className="col-md-12">
                                <input type="text" className="form-control save-popup-textbox" defaultValue={insightTitle} />
                            </div>
                            <span className="hint-span">
                                Hint: Make sure the title is recognizable so it can be found easily next time.
                            </span>
                            <div className='row footer-border'>
                                <div className='col-md-8'></div>
                                <div className='col-md-4'>
                                    <button type="button" className="btn btn-link" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-link" >Save</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    );
});

export default ModalComponent;
