import InsightDropdown from '../InsightBuilder/InsightDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedInsight, updateCategoriesFromApi, updateFeaturesFromApi, updateVehiclesFromApi } from '../store/actions';
import FeaturesDropdown from '../InsightBuilder/FeaturesDropdown';
import VehiclesDropdown from '../InsightBuilder/VehiclesDropdown';
import { useState, useRef, useEffect } from 'react';
import { getFeaturesApi, getVehiclesApi } from '../apis/dropdowns';
import { responses } from '../utils/responses'
import { processVehiclesData } from '../utils/processVehicles';
import ResultTable from './ResultTable';
import { getInsightDataApi } from '../apis/insight';
import { Button } from 'react-bootstrap';
// import { DownloadTableExcel } from "react-export-table-to-excel";
import DownloadTableExcelSheetJS from '../ExportInsight/HTMLTOExcel/DownloadTableExcelSheetJS';
import axios from 'axios';

import greenTick from "../assets/img/green_tick.png";
import newImg from "../assets/img/new.png";
import newInactiveImg from "../assets/img/new_inactive.png";
import favImg from "../assets/img/fav.png";
import favInactiveImg from "../assets/img/fav_inactive.png";
import favSavedImg from "../assets/img/fav_saved.png";
import recentImg from "../assets/img/recent.png";
import recentWhiteImg from "../assets/img/recent_white_skin.png";
import export_new_skinImg from "../assets/img/export_new_skin.png";
import exportInactiveImg from "../assets/img/export_inactive.png";
import boxImg from "../assets/img/box.gif";

import ModalComponent from "../ModalComponents/ModalComponent";
import TourModalComponent from "../ModalComponents/TourModalComponent";
import SaveInsightComponent from "../ModalComponents/SaveInsightComponent";
import DownloadComponent from "../ModalComponents/DownloadComponent";
import DownloadProcessComponent from "../ModalComponents/DownloadProcessComponent";
import cookie from 'react-cookies'
import { saveInsightDataApi } from '../apis/insightAction';
import { saveFavoriteInsightApi, removeFavoriteInsightApi, listInsightApi, listFavoriteInsightApi, getInsightApi } from '../apis/insightAction';
import TrimShareTable from './TrimShareTable';

const InsightBuilder = ({ homePageUpdateFeatures, homePageInsight, homePageVehicles }) => {

    const [isTrimShare, setIsTrimShare] = useState(false)
    const [trimShareData, setTrimShareData] = useState([])

    const [userId, setUserId] = useState(cookie.load('user_id') == undefined ? 1 : cookie.load('user_id'))

    const [insightDataFromApi, setInsightDataFromApi] = useState({
        created_at: '', id: '', insight_name: '', is_favorite: 0, updated_at: '', user_id: '', hasData: false,
        insight_selection: {
            features: [], insight: '', name: '', newFeaturesList: [], selectedInsightId: 0, vehicles: []
        }
    })

    const dispatch = useDispatch();

    const insightDropdownData = useSelector(state => state.insightsDropdownData)
    // const featureCategories = useSelector( state => state.featureCategories)
    // const featuresData = useSelector( state => state.featuresData)
    // const selectedVehicles = useSelector(state => state.selectedVehicles)
    const featuresDropdownRef = useRef();

    const triggerChildUpdateSelectedfeatures = (features) => {
        featuresDropdownRef.current.updateSelectedfeatures(features);
    };

    const [featureCategories, setFeaturecategories] = useState([])
    const [featuresData, setFeaturesData] = useState([])
    const [vehiclesData, setVehiclesData] = useState([])
    const [selectedInsightId, setSelectedInsightId] = useState(0)

    // State to check is dropdown selected.
    const [isInsightDropdownSelected, setInsightDropdownSelected] = useState(false)
    const [isFeatureDropdownSelected, setFeatureDropdownSelected] = useState(false)
    const [isFeatureDropdownGreeTick, setFeatureDropdownGreeTick] = useState(true)
    const [isVehiclesDropdownSelected, setVehiclesDropdownSelected] = useState(false)

    const [isDropdownSelected, setDropdownSelected] = useState(false)
    const [insightPayloadData, setInsightPayloadData] = useState({})

    const [selectedFeaturesFD, setSelectedfeaturesFD] = useState([])

    let [features, setFeatures] = useState([])
    const [insight, setInsight] = useState("")
    const [vehicles, setVehicles] = useState([])
    const [resultTblHeaders, setResultTblHeaders] = useState([])
    const [headersWithValues, setHeadersWithvalues] = useState([])
    const [resultData, setResultData] = useState([])
    const [resultDataOrg, setResultDataOrg] = useState([])
    const [vehKeys, setVehKeys] = useState([])
    const [featureIds, setFeatureIds] = useState([])
    const [processTblHeaders, setProcessTblheaders] = useState(true)
    const [tblVehicles, setTblVehicles] = useState([])
    let loacalTblVehicles = []

    const onInsghtSelect = async (insightId, submenu, isChecked) => {
        // console.log('insightId', insightId, submenu.id, isChecked)
        setSelectedInsightId(submenu.id)
        await getFeatures(submenu.name)
        // setInsightDropdownSelected(true)
        dispatch(saveSelectedInsight({ insightId: insightId, submenuId: submenu.id, isChecked: isChecked, submenu: submenu }))
    }

    const [featuresNVehicleLoading, setFeaturesNVehicleLoadingLoading] = useState(false);

    const getFeatures = async (insight) => {
        try {
            setFeaturesNVehicleLoadingLoading(true); // Set loading to true before API calls

            let featuresApiResponse = await getFeaturesApi({ insight: insight })
            let form = []
            vehicles.forEach(item => {
                if (item.category == "Model Year") {
                    form.push({...item})
                }
            })
            console.log('getFeatures: ', form)
            if (featuresApiResponse.data.result == responses.RESULT_OK) {
                let categories = [...processcategories(featuresApiResponse.data.categories)]
                setFeaturecategories([...categories])
                dispatch(updateCategoriesFromApi(categories))
                setFeaturesData([...featuresApiResponse.data.features])
                dispatch(updateFeaturesFromApi(featuresApiResponse.data.features))
            }
            if(form.length > 0){
                await onVehicleSelection('Model Year', form, insight)
            }else{
                let vehicleApiResponse = await getVehiclesApi(null, insight);
                setVehiclesData([...processVehiclesData(vehicleApiResponse.data.vehicles)])
                dispatch(updateVehiclesFromApi([...processVehiclesData(vehicleApiResponse.data.vehicles)]))
            }
            
            //// console.log(processVehiclesData(vehicleApiResponse.data.vehicles))
            
        } catch (ex) {
            //// console.log(ex)
        } finally {
            setFeaturesNVehicleLoadingLoading(false); // Set loading to false after API calls, whether they succeeded or failed
        }
    }

    const fetchInsightListData = async () => {
        try {
            setLoading(true);
            // Perform your API call here

            listInsightApi(userId)
                .then((response) => {
                    if (response.data.result == responses.RESULT_OK) {

                        // Update state with the fetched data
                        setFullInsightListData(response.data.data);

                        // chunk first 5 record
                        const firstFiveRecords = response.data.data.slice(0, 5);
                        setInsightListData(firstFiveRecords);

                        setLoading(false);
                    } else {
                        // console.log('false:', response.data.msg);
                    }
                })
                .catch((error) => {
                    // console.log('catch:', error.message);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // Set loading to false once the API call is completed
            setLoading(false);
        }
    };

    const fetchFavInsightListData = async () => {
        try {
            setLoading(true);
            // Perform your API call here

            listFavoriteInsightApi(userId)
                .then((response) => {
                    if (response.data.result == responses.RESULT_OK) {

                        // Update state with the fetched data
                        setFullFavInsightListData(response.data.data);

                        // chunck first 5
                        const firstFiveRecords = response.data.data.slice(0, 5);
                        setFavInsightListData(firstFiveRecords);

                        setLoading(false);
                    } else {
                        // console.log('false:', response.data.msg);
                    }
                })
                .catch((error) => {
                    // console.log('catch:', error.message);
                });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // Set loading to false once the API call is completed
            setLoading(false);
        }
    };

    let insightBuildPayloadData = {};
    const SaveBuildInsight = async () => {
        // Make the API call after setting the state
        try {
            const response = await saveInsightDataApi({ ...insightBuildPayloadData });
            if (response.data.result === responses.RESULT_OK) {
                localStorage.setItem('favInsightId', response.data.data.id);
                fetchInsightListData();
                // console.log('Build-Saved');
            } else {
                // console.log('Insight save failed:', response.data.msg);
            }
        } catch (error) {
            // console.log('catch:', error.message);
        }
    };

    const processcategories = (data) => {
        let categories = []
        data.forEach(item => {
            categories.push(item.vp_category)
        })
        return categories
    }

    const vehDropdownItems = ["Model Year", "Brand Type", "Brand", "Vehicle Type", "Segments", "Model", "Trim"]

    const onVehicleSelection = async (category, data, custInsight = null) => {
        console.log('onVehicleSelection: ', category, data)
        let form = {
            year: [],
            class: [],
            make: [],
            vehicle_type: [],
            segment_name: [],
            model: []
        }
        data.forEach(item => {
            if (item.category == "Model Year") {
                form = { ...form, year: [...item.values] }
            }
            if (item.category == "Brand Type") {
                form = { ...form, class: [...item.values] }
            }
            if (item.category == "Brand") {
                form = { ...form, make: [...item.values] }
            }
            if (item.category == "Vehicle Type") {
                form = { ...form, vehicle_type: [...item.values] }
            }
            if (item.category == "Segments") {
                form = { ...form, segment_name: [...item.values] }
            }
            if (item.category == "Model") {
                form = { ...form, model: [...item.values] }
            }
        })
        let vehicleApiResponse = await getVehiclesApi(form, custInsight == null ? insight: custInsight, custInsight);
        // console.log(vehicleApiResponse)
        let vehicles = [...processVehiclesData(vehicleApiResponse.data.vehicles)]
        let presentData = [...vehiclesData]
        let affecteCategories = []
        let maxIndex = custInsight == null ? vehDropdownItems.indexOf(category) : -1;
        // data.forEach(item => {
        //     let i = vehDropdownItems.indexOf(item.category)
        //     if (i > maxIndex) {
        //         maxIndex = i
        //     }
        // })
        vehDropdownItems.forEach((item, i) => {
            if (i > maxIndex) {
                affecteCategories.push(item)
            }
        })
        affecteCategories.forEach(item => {
            let v = { ...vehicles.find(vp => vp.category == item) }
            let tpData = presentData.map(i => {
                if (i.category == item) {
                    i.values = [...v.values]
                }
                return i
            })
            presentData = [...tpData]
        })
        // // console.log('affecteCategories: ', affecteCategories, maxIndex, category)
        // setVehiclesDropdownSelected(true)
        setVehiclesData([...presentData])
        dispatch(updateVehiclesFromApi([...presentData]))
    }
    const [selectedFeatures, setSelectedFeatures] = useState([])
    let localSelectedFeatures = []

    const insightBuilderUpdateFeatures = (data) => {
        homePageUpdateFeatures([...data])
        setSelectedFeatures([...data])
        //// console.log("sgjkashaskjlfasklhsakljas", [...data])
        localSelectedFeatures = [...data]
        //// console.log("ggasgasgssgasg", localSelectedFeatures)
        getUpdatedFeatures([...data])

    }

    const getUpdatedInsight = (inp) => {

        // Reset step 2 - Start
        setFeatureIds([])
        setResultData([])
        setResultDataOrg([])
        setFeatures([])
        setResultTblHeaders([])
        setHeadersWithvalues([])
        setSelectedfeaturesFD([])
        // Reset step 2 - End

        setInsightDropdownSelected(true)
        setFeatureDropdownGreeTick(true)
        setFeatureDropdownSelected(false)
        setVehiclesDropdownSelected(false)

        insightBuilderUpdateFeatures([])

        setInsight(inp)
        if (inp == 'By Package (Export)' || inp == 'Pricing Ladder' || inp == 'Trim Share') {
            setInsightDropdownSelected(false)
            setFeatureDropdownSelected(true)
            setFeatureDropdownGreeTick(false)
        }
        checkForTrimAndModels(inp)
        triggerChildUpdateSelectedfeatures([])
    }

    const checkForTrimAndModels = (insight, data = null) => {
        //// console.log("checkForTrimAndModels ", insight, data)
        let vehs = []
        if (data == null) {
            vehs = [...vehicles]
        } else {
            vehs = [...data]
        }

        if (insight == "Percentage of trims") {
            vehs = vehs.filter(veh => veh.category != "Trim")
            getUpdatedVehicles([...vehs], false)
            return true;
        }
        if (insight == "Percentage of Models") {
            vehs = vehs.filter(veh => veh.category != "Trim" && veh.category != "Model")
            getUpdatedVehicles([...vehs], false)
            return true;
        }
        getUpdatedVehicles([...vehs], false)
    }

    const getUpdatedFeatures = (inp) => {
        setFeatures([...inp])
        processHeaders([...inp])
    }

    const processHeaders = (inp, override = false) => {
        if (processTblHeaders || override) {
            let headerData = sortFeatures([...inp])
            setResultTblHeaders([...headerData.headers])
            setHeadersWithvalues([...headerData.finalData])
            setFeatureIds([...headerData.finalData.map(d => d.id)])
            setTblVehicles([...vehicles])
            loacalTblVehicles = [...vehicles]
        }
    }

    const getUpdatedVehicles = (inp, processModels = true) => {
        if (processModels) {
            checkForTrimAndModels(insight, [...inp])
        } else {
            setVehicles([...inp])
        }
        if (processTblHeaders) {
            setTblVehicles([...inp])
            loacalTblVehicles = [...inp]
        }
    }

    const sortFeatures = (input) => {
        // // console.log('Insight Builder - 238: ', input)
        let headers = []
        let tempData = []
        let data = []
        input.forEach(ele => {
            let header = getHeader(ele)
            tempData.push({ ...header })
            if (headers.includes(header.header) == false) {
                headers.push(header.header)
            }
        });
        headers = headers.sort()
        headers.forEach(header => {
            let items = tempData.filter(item => item.header == header)
            items = items.sort((a, b) => {
                if (a.value == "Any") {
                    return -1;
                }
                if (a.value < b.value) {
                    return -1;
                }
            })
            data = data.concat([...items])
        })
        let finalData = []
        data.forEach(ele => {
            let d = finalData.filter(e => e.header == ele.header && e.value == ele.value)
            if (d.length == 0) {
                finalData.push(ele)
            }
        })
        let finalheaders = []
        headers.forEach(header => {
            finalheaders.push({ title: header, cnt: finalData.filter(e => e.header == header).length })
        })
        let newFeaturesList = getFeatureCombinatios(data, finalData)
        // // console.log('Insight Builder - 273: ', { data, finalData: finalData, headers: finalheaders })
        return { finalData: finalData, headers: finalheaders, newFeaturesList: newFeaturesList };
    }

    const getFeatureCombinatios = (inpData, headersData) => {
        let finalData = []
        // // console.log('getFeatureCombinatios - 280: ', inpData, headersData)
        headersData.forEach(hd => {
            let tempData = inpData.filter(e => e.header == hd.header && e.value == hd.value)
            if (tempData.length > 0) {
                let obj = { id: tempData[0].id }
                obj.sfids = [...tempData.map(tmp => tmp.id)]
                finalData.push(obj)
            }
        })
        return finalData
    }

    const getHeader = (input) => {
        //// console.log(input)
        if (input.vp_sub_value != null && input.vp_sub_value.length > 0) {
            return { header: input.vp_value, value: input.vp_sub_value, id: input.sbd_feature_id }
        }
        if (input.vp_value != null && input.vp_value.length > 0) {
            return { header: input.vp_name, value: input.vp_value, id: input.sbd_feature_id }
        }
        return { header: input.vp_category, value: input.vp_name, id: input.sbd_feature_id }
    }

    /*
    Model Year,  Brand Type
    Brand,  Vehicle Type
    Segments, Model, Trim
    */

    const processApiData = async (data, insight) => {
        //// console.log(vehicles)
        //   let data = [
        //     {
        //         "Model Year": 2024, "Brand": "Audi", featureId: 5, value: "Yes"
        //     },
        //     {
        //         "Model Year": 2024, "Brand": "Audi", featureId: 6, value: "Yes"
        //     },
        //     {
        //         "Model Year": 2024, "Brand": "BMW", featureId: 5, value: "Yes"
        //     },
        //     {
        //         "Model Year": 2024, "Brand": "BMW", featureId: 6, value: "Yes"
        //     }
        // ]
        if (data.length > 0) {
            let keys = Object.keys(data[0])
            keys = keys.filter(key => key != "featureId" && key != "value" && key != "count")
            let tempData = []
            data.forEach(ele => {

                let obj = tempData.find(item => {
                    let result = true
                    keys.forEach(key => {
                        if (result == true) {
                            result = ele[key] == item[key]
                        }
                    })
                    return result
                })
                if (obj) {
                    obj.data = [...obj.data, { id: ele.featureId, value: formtValue(ele.value, insight) }]
                } else {
                    let newObj = {}
                    keys.forEach(key => {
                        newObj[key] = ele[key]
                    })
                    newObj.data = [{ id: ele.featureId, value: formtValue(ele.value, insight) }]
                    tempData.push({ ...newObj })
                }

            })
            //// console.log("result table data ", [...tempData])
            setResultData([...tempData])
            setResultDataOrg([...tempData]);
            setVehKeys([...keys])
        } else {
            setResultData([])
            setVehKeys([])
        }

    }

    const formtValue = (value, insight) => {

        if (insight == "Fitment Type") {
            if (value == "" || value == "na") {
                return "N/A"
            }
            if (value == "std") {
                return "Standard"
            }
            if (value == "opt") {
                return "Optional"
            }
        }


        if (insight == "Yes / No") {
            if (value == "" || value == "NA") {
                return "N"
            }
            if (value == "yes") {
                return "Y"
            }
            if (value == "no") {
                return "N"
            }
        }

        if (insight == "Percentage of Models" || insight == "Percentage of trims") {
            if (value == "" || value == "NA") {
                return "0%"
            }
            return value + "%"
        }

        if (insight == "By Feature") {
            if (value == "" || value == "NA") {
                return "0%"
            }
            return parseFloat(value) + "%"
        }

        if (insight == "Minimum" || insight == "Maximum" || insight == "Average") {
            //// console.log(1, value, insight)
            if (isNaN(value)) {
                return "N/A"
            }
            return value
        }

        return value
    }

    const downloadModalRef = useRef();
    const downloadProcessModalRef = useRef();
    const saveModalRef = useRef();

    const handleShowDownloadModal = () => {
        // Access the showModal function through the ref
        downloadModalRef.current.showModal();
    };

    const [isApiInProgress, setApiInProgress] = useState(false)
    const [processedInsight, setProcessedInsight] = useState("")
    let testProcessedInsight = "";

    const getInshgtData = async () => {
        if (features.length == 0 && insightDataFromApi.hasData) {
            let selfeatures = []
            insightDataFromApi.insight_selection.features.forEach(featureID => {
                let featureToSelect = featuresData.find(item => item.sbd_feature_id === featureID);
                if (featureToSelect !== undefined) {
                    selfeatures.push({ ...featureToSelect })
                }
            })
            features = [...selfeatures]
        }
        let makeApiCall = false;
        if (selectedInsightId == 17 || selectedInsightId == 18 || selectedInsightId == 20) {
            makeApiCall = vehicles.length > 0
        } else {
            makeApiCall = vehicles.length > 0 && features.length > 0
        }
        // console.log('makeApiCall', makeApiCall, vehicles.length, features)
        if (makeApiCall) {
            try {
                let insightTitleString = '';
                if (selectedInsightId == 18) {
                    setApiInProgress(true)

                    let reqObj = {
                        column_filters: '', columns: '', insight: 27, insight_name_col_filter: '', insight_name_row_filter: "10_&_[]", master_filters: "{\"master_filter\":\"\",\"show\":\"\"}",
                        result_insights: [], row_filters: '10_&_[]', rows: 10, timezone_name: "Asia/Calcutta"
                    }
                    let filters = [];
                    let columns = [];
                    vehicles.forEach(veh => {
                        if (veh.category == "Model Year") {
                            columns.push(2)
                            filters.push("2_&_" + veh.values.toString())
                        }
                        if (veh.category == "Brand Type") {
                            columns.push(11)
                            filters.push("11_&_" + veh.values.toString())
                        }
                        if (veh.category == "Brand") {
                            columns.push(1)
                            filters.push("1_&_" + veh.values.toString())
                        }
                        if (veh.category == "Vehicle Type") {
                            columns.push(17)
                            filters.push("17_&_" + veh.values.toString())
                        }
                        if (veh.category == "Segments") {
                            columns.push(9)
                            filters.push("9_&_" + veh.values.toString())
                        }
                        if (veh.category == "Model") {
                            columns.push(3)
                            filters.push("3_&_" + veh.values.toString())
                        }
                    })
                    reqObj = { ...reqObj, columns: columns.join(','), column_filters: filters.join(';'), insight_name_col_filter: filters.join(';') }

                    let url = 'https://www.vehicleplannerplus.com/export/pricing-ladder'
                    let apiResponse = await axios.post(url, reqObj, { responseType: "blob" })
                    const url2 = window.URL.createObjectURL(new Blob([apiResponse.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
                    const link = document.createElement('a');

                    link.href = url2;
                    link.setAttribute('download', 'VehiclePlannerPlus Insights.xlsx');
                    document.body.appendChild(link);
                    link.click();
                } else {
                    setProcessTblheaders(true)
                    processHeaders([...features], true)
                    setApiInProgress(true)
                    let headerData = sortFeatures([...features])

                    if (selectedInsightId == 17 || selectedInsightId == 19) {
                        downloadModalRef.current.hideModal();
                        // show progress modal
                        downloadProcessModalRef.current.showModal();
                    }
                    let response = await getInsightDataApi({ features: [...headerData.finalData.map(d => d.id)], vehicles: vehicles, insight: insight, newFeaturesList: headerData.newFeaturesList }, selectedInsightId)
                    // console.log('response: ', response)
                    if(selectedInsightId == 20){
                        setTrimShareData([...response.data.data])
                        setIsTrimShare(true)
                    }else{
                        setIsTrimShare(false)
                        setTrimShareData([])
                    }
                    if (selectedInsightId == 17) {

                        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        // const blob = new Blob([response.data]);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'Package Penetration Rate Insights.xlsx';
                        link.click();
                        // hide progress modal
                        downloadProcessModalRef.current.hideModal();
                        insightTitleString = 'Package Penetration Rate Insights';

                    } else if (selectedInsightId == 19) {

                        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        // const blob = new Blob([response.data]);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'Package Penetration Rate By Feature Insights.xlsx';
                        link.click();
                        // hide progress modal
                        downloadProcessModalRef.current.hideModal();
                        insightTitleString = 'Package Penetration Rate By Feature Insights';

                    } else {
                        if (response.data.result == responses.RESULT_OK) {
                            setProcessedInsight(insight)
                            testProcessedInsight = insight
                            processApiData(response.data.data, insight)
                            insightTitleString = getInsightTitle()
                            setDropdownSelected(true)
                            // console.log('Build-generated')
                        } else {
                            //// console.log(response.data.msg)
                        }
                    }
                    if (response.data.result == responses.RESULT_OK) {
                        insightBuildPayloadData = {
                            insight_selection: {
                                features: [...headerData.finalData.map(d => d.id)],
                                vehicles: vehicles,
                                insight: insight,
                                name: insightTitleString,
                                newFeaturesList: headerData.newFeaturesList,
                                selectedInsightId: selectedInsightId
                            },
                            is_favorite: 0,
                            name: insightTitleString,
                            user_id: cookie.load('user_id'),
                        }
                        setInsightPayloadData(insightBuildPayloadData)
                        SaveBuildInsight();
                    }
                }
                setApiInProgress(false)
                setProcessTblheaders(false)
            } catch (er) {
                //// console.log(er.message)
                setApiInProgress(false)
            }
        } else {
            //// console.log(vehicles.length, features.length)
        }
    }

    const [showInsightMenu, setShowInsightMenu] = useState(false)
    const [showFeaturesMenu, setShowFeaturesMenu] = useState(false)
    const [showVehiclesMenu, setShowVehiclesMenu] = useState(false)

    const fromInsight = (val) => {
        setShowInsightMenu(val)
        if (val) {
            setShowFeaturesMenu(false)
            setShowVehiclesMenu(false)
        }
    }

    const fromFeature = (val) => {
        setShowFeaturesMenu(val)
        if (val) {
            setShowInsightMenu(false)
            setShowVehiclesMenu(false)
        }
    }

    const fromVehicles = (val) => {
        setShowVehiclesMenu(val)
        if (val) {
            setShowInsightMenu(false)
            setShowFeaturesMenu(false)
        }
    }

    const [insightTitle, setInsightTitle] = useState("")

    const getInsightTitle = () => {
        let title = "";
        if (loacalTblVehicles.length > 0) {
            title = testProcessedInsight + " offered with " + getFeaturepartForInsightTitle() + " across " + getVehiclePartForInsightTitle()
        }
        setInsightTitle(title)
        return title;
        //// console.log('tblVehicles', tblVehicles, selectedFeatures, localSelectedFeatures, title)
    }

    const resultTableRef = useRef(null);
    const innerComponentRef = useRef(null);
    const resultTrimShareTableRef = useRef(null)

    const handleShowSaveModal = () => {
        // Access the showModal function through the ref
        saveModalRef.current.showModal();
    };

    const handleOuterComponentClick = () => {
        // Trigger the click event of InnerComponent using the ref
        if (innerComponentRef.current) {
            innerComponentRef.current.handleClick();
        }
    };

    const getFeaturepartForInsightTitle = () => {
        // console.log("localSelectedFeatures:: ", localSelectedFeatures)
        let unqiue = getUniqueObjects(selectedFeatures)
        // console.log("localSelectedFeatures:: ", unqiue)
        if (unqiue.length == 1) {
            return unqiue[0].vp_name;
        }
        if (unqiue.length > 1) {
            // console.log('getFeaturepartForInsightTitle: ', selectedFeatures)
            return unqiue.length + " features"
        }
        return " "
    }

    function getUniqueObjects(jsonArray) {
        // console.log('getUniqueObjects: ', jsonArray)
        jsonArray = jsonArray.map( e => {
            return  {vp_category: e.vp_category, vp_name: e.vp_name, vp_value: e.vp_value, vp_sub_value: e.vp_sub_value}
        })
        const seenObjects = new Set();
        return jsonArray.filter(obj => {
            // Convert each object to a string representation for comparison
            const objString = JSON.stringify(obj);

            // Check if the string representation is unique
            if (!seenObjects.has(objString)) {
                seenObjects.add(objString);
                return true;
            }

            return false;
        });
    }



    const getVehiclePartForInsightTitle = () => {
        let lastItem = loacalTblVehicles[loacalTblVehicles.length - 1];
        let text = ""
        if (lastItem.category == "Model Year") {
            let values = lastItem.values.sort(function (a, b) {
                return a - b;
            })
            if (values.length <= 2) {
                if (values.length == 1) {
                    text = makeMyInsightYearText(values[0]) + " vehicles"
                }
                if (lastItem.values.length == 2) {
                    text = makeMyInsightYearText(values[0]) + " - " + makeMyInsightYearText(values[1]) + " vehicles"
                }
            } else {
                text = makeMyInsightYearText(values[0]) + " - " + makeMyInsightYearText(values[values.length - 1]) + " vehicles"
            }
        } else {
            if (lastItem.values.length <= 2) {
                if (lastItem.values.length == 1) {
                    text = lastItem.values[0]
                }
                if (lastItem.values.length == 2) {
                    text = lastItem.values[0] + " and " + lastItem.values[1]
                }
            } else {
                text = lastItem.values.length + " " + (lastItem.category == "Segments" ? "Segments" : lastItem.category + "s")
            }
        }
        return text
    }

    const makeMyInsightYearText = (value) => {
        value = value.toString()
        let text = "MY" + value[2] + value[3]
        //// console.log(text, value)
        return text
    }

    // Main Container Component
    const [isFavorite, setIsFavorite] = useState(false);
    const [isInsights, setIsInsights] = useState(false);

    const [isRecentTab, setIsRecentTab] = useState(false);
    const [isFavoritesTab, setIsFavoritesTab] = useState(false);

    let thisInsightId = 0;

    // Favorit button
    const toggleFavorite = () => {
        setInsightPayloadData((prevData) => ({
            ...prevData,
            name: insightTitle,
            id: thisInsightId
        }));

        if (isFavorite) {
            if (Object.keys(insightPayloadData).length > 0) {

                // Wait for setInsightPayloadData to complete
                // setInsightPayloadData(prevData => {
                // Make the API call after setting the state
                try {
                    removeFavoriteInsightApi({ ...insightPayloadData, id: localStorage.getItem('favInsightId'), user_id: cookie.load('user_id') })
                        .then((response) => {
                            // console.log('Fav remove Response', response.data.result);
                            if (response.data.result == responses.RESULT_OK) {
                                thisInsightId = localStorage.getItem('favInsightId');
                                fetchInsightListData()
                                fetchFavInsightListData()
                                setIsFavorite(false);
                            } else {
                                //// console.log('false:', response.data.msg);
                            }
                        })
                        .catch((error) => {
                            //// console.log('catch:', error.message);
                        });
                } catch (error) {
                    //// console.log('catch:', error.message);
                }
                //     return prevData;
                // });
            } else {
                //// console.log('insightPayloadData length is 0 : ', insightPayloadData);
            }
        } else {
            if (Object.keys(insightPayloadData).length > 0) {

                // Wait for setInsightPayloadData to complete
                // setInsightPayloadData(prevData => {
                // Make the API call after setting the state
                try {
                    saveFavoriteInsightApi({ ...insightPayloadData, id: localStorage.getItem('favInsightId'), user_id: cookie.load('user_id') })
                        .then((response) => {
                            // console.log('Fav Response', response.data.result);
                            if (response.data.result == responses.RESULT_OK) {
                                thisInsightId = localStorage.getItem('favInsightId');
                                fetchInsightListData()
                                fetchFavInsightListData()
                                setIsFavorite(true);
                            } else {
                                // console.log('false:', response.data, responses.RESULT_OK);
                            }
                        })
                        .catch((error) => {
                            //// console.log('catch:', error.message);
                        });
                } catch (error) {
                    //// console.log('catch:', error.message);
                }
                //     return prevData;
                // });
            } else {
                //// console.log('insightPayloadData length is 0 : ', insightPayloadData);
            }
        }
    };

    // insight button
    const toggleInsights = () => {
        setIsInsights(!isInsights);
    };
    // recent tab in insight popup
    const toggleRecentTab = () => {
        setIsRecentTab(true);
        setIsFavoritesTab(false);
    };
    // Favorites tab in insight popup
    const toggleFavoritesTab = () => {
        setIsFavoritesTab(true);
        setIsRecentTab(false);
    };

    // Main container and insight Container
    const [isMainContainer, setIsMainContainer] = useState(false);
    const [isInsightsContainer, setIsInsightsContainer] = useState(false);

    // show and hide main container
    const showMainContainer = () => {
        setIsMainContainer(true);
        setIsInsightsContainer(false);
    };

    // show and hide insight container
    const showInsightsContainer = () => {
        // check tab status to show same tab in insight
        if (isRecentTab) {
            showInsightsContainerRecentTab()
        } else {
            showInsightsContainerFavoritesTab()
        }
        setIsInsightsContainer(true);
        setIsMainContainer(false);
        // reset insite popup
        toggleRecentTab();
        toggleInsights();
    };

    const [isInsightsContainerRecentTab, setIsInsightsContainerRecentTab] = useState(false);
    const [isInsightsContainerFavoritesTab, setIsInsightsContainerFavoritesTab] = useState(false);

    const showInsightsContainerRecentTab = () => {
        setIsInsightsContainerRecentTab(true);
        setIsInsightsContainerFavoritesTab(false);
    };
    const showInsightsContainerFavoritesTab = () => {
        setIsInsightsContainerFavoritesTab(true);
        setIsInsightsContainerRecentTab(false);
    };

    // create func to sort data
    const sortResultTable = (type, key) => {
        
        let sortedNestedData = []
        if( ['asc','dsc'].includes(type)){
            sortedNestedData = resultData.sort((a, b) => {
                const aValue = a.data.find(ele => ele.id == key)?.value;
                const bValue = b.data.find(ele => ele.id == key)?.value;
                if (aValue < bValue) {
                    return type === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return type === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }else{
            sortedNestedData = resultData.sort((a, b) => {
                const aValue = parseInt(a.data.find(ele => ele.id == key)?.value) || 0;
                const bValue = parseInt(b.data.find(ele => ele.id == key)?.value) || 0;
    
                if (aValue < bValue) {
                    return type === 'lowest' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return type === 'lowest' ? 1 : -1;
                }
                return 0;
            });
        }
        
        setResultData(sortedNestedData);
    };

    // Create func to filter data
    const filterResultTable = (type, key, searchVal) => {
        let filteredData;

        // Check if resultDataOrg is defined
        if (resultDataOrg) {
            filteredData = resultDataOrg.filter((item) => {
                if (searchVal) {
                    // Use find instead of filter to get the first matching element
                    let filterElement = item.data.find(ele => ele.id == key);

                    // Check if filterElement is defined before accessing its value
                    let itemValue = filterElement ? parseFloat(filterElement.value) : null;
                    // Handle NaN values when parsing integers
                    if (isNaN(itemValue)) {
                        itemValue = null;
                    }

                    switch (type) {
                        case 'equal':
                            return itemValue == parseFloat(searchVal);
                        case 'greater':
                            return itemValue > parseFloat(searchVal);
                        case 'less':
                            return itemValue < parseFloat(searchVal);
                        default:
                            return true; // No filtering if type is not recognized
                    }
                } else {
                    return true;
                }
            });
        } else {
            // Handle the case where resultDataOrg is not defined
            console.error("resultDataOrg is not defined.");
            filteredData = [];
        }

        setResultData(filteredData);
    };

    const filterBasedOnCheckBoxes = (key, filters) =>{
        // console.log('filterBasedOnCheckBoxes: ', filters, resultDataOrg)
        let filterKeys = Object.keys(filters)
        let values = []
        // N/A  Optional 
        filterKeys.forEach(k =>{
            if(k == 'std' && filters[k] == true){
                values.push('Standard')
            }
            if(k == 'opt' && filters[k] == true){
                values.push('Optional')
            }
            if(k == 'na' && filters[k] == true){
                values.push('N/A')
            }
            if(k == 'mixed' && filters[k] == true){
                values.push('Mixed')
            }

            if(k == 'yes' && filters[k] == true){
                values.push('Y')
            }
            if(k == 'no' && filters[k] == true){
                values.push('N')
            }

        })
        // console.log('filterBasedOnCheckBoxes: ', values)
        if(values.length == 0){
            setResultData([...resultDataOrg])
        }else{
            let tmpData = []
            tmpData = resultDataOrg.filter((item) => {
                let filterElement = item.data.find(ele => ele.id == key)
                return values.includes( filterElement.value )
            })
            setResultData([...tmpData])
        }
    }

    // create func to reset all
    const resetAll = () => {
        // Redirect to the "/" route
        window.location.href = '/';
    };

    // Small list of insights
    const [insightListData, setInsightListData] = useState(null);
    const [favInsightListData, setFavInsightListData] = useState(null);
    // full list of insights
    const [fullInsightListData, setFullInsightListData] = useState(null);
    const [fullFavInsightListData, setFullFavInsightListData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchInsightListData(); // Call the fetchData function when the component mounts
        fetchFavInsightListData(); // Call the fetchData function when the component mounts

        // // console.log('small list data', insightListData.slice(0, 8));
        // If you want to clean up (cancel the API call) when the component unmounts,
        // you can return a cleanup function from useEffect
        // return () => {
        // Cleanup code if needed
        // };
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('data')) {
            setDataAndLoadTable(queryParams.get('data'))
        }

    }, []); // The empty dependency array [] ensures that the effect runs only once on component mount

    // FeaturesDropdown states
    const [block2Data, setBlock2Data] = useState([])
    const [block3Data, setBlock3Data] = useState([])
    const [block4Data, setBlock4Data] = useState([])
    const exportRef = useRef(null);

    const setDataAndLoadTable = (itemId, isSetUI = true) => {

        // Set data to query parameter
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('data', itemId);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);

        showMainContainer()
        setIsInsights(false)
        // Call API to fetch data
        fetchData(itemId, isSetUI);
    };
    let [dataId, setDataId] = useState("")
    const fetchData = async (itemId, isSetUI) => {
        try {
            // Call your API endpoint with the itemId
            const { data, success } = await fetchInsightByID(itemId); // Await the fetchInsightByID function
            if (success) {

                if (isSetUI) {
                    // Set Insight Dropdown selection
                    // await selectInsight(data.insight_selection.selectedInsightId, data.insight_selection.insight)
                    // // console.log(':: selectInsight Done Execute 2nd step ::')

                    // // Set Fetures and Vehicle Dropdown selection
                    // await selectFeaturesAndVehicles(data.insight_selection.features, data.insight_selection.vehicles)
                    // // console.log(':: Fetures and Vehicle Dropdown selection Done show result data ::')

                    // // Handle the fetched data as per your requirement
                    // setSelectedInsightId(data.insight_selection.selectedInsightId)
                    setInsightDataFromApi({ ...data, hasData: true })
                    let selfeatures = []
                    data.insight_selection.features.forEach(featureID => {
                        let featureToSelect = featuresData.find(item => item.sbd_feature_id === featureID);
                        if (featureToSelect !== undefined) {
                            selfeatures.push({ ...featureToSelect })
                        }
                    })
                    insightBuilderUpdateFeatures([...selfeatures])
                    setDataId(Math.random())

                }

                // Fetch Result Data
                let response = await getInsightDataApi(data.insight_selection, data.insight_selection.selectedInsightId)
                // console.log('response - 643: ', [...response.data])
                if(selectedInsightId == 20){
                    setTrimShareData([...response.data])
                    setIsTrimShare(true)
                }else{
                    setIsTrimShare(false)
                    setTrimShareData([])
                }
                if (selectedInsightId == 17) {

                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Package Penetration Rate Insights.xlsx';
                    document.body.appendChild(link);  // Append link to the body
                    link.click();
                    document.body.removeChild(link);  // Remove the link after clicking

                    // const blob = new Blob([response.data]);
                    // const link = document.createElement('a');
                    // link.href = window.URL.createObjectURL(blob);
                    // link.download = 'Package Penetration Rate Insights.xlsx';
                    // link.click();
                    // hide progress modal
                    downloadProcessModalRef.current.hideModal();

                } else if (selectedInsightId == 19) {

                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Package Penetration Rate By Feature Insights.xlsx';
                    document.body.appendChild(link);  // Append link to the body
                    link.click();
                    document.body.removeChild(link);  // Remove the link after clicking


                    // const blob = new Blob([response.data]);
                    // const link = document.createElement('a');
                    // link.href = window.URL.createObjectURL(blob);
                    // link.download = 'Package Penetration Rate By Feature Insights.xlsx';
                    // link.click();
                    // hide progress modal
                    downloadProcessModalRef.current.hideModal();

                } else {
                    if (response.data.result == responses.RESULT_OK) {
                        setProcessedInsight(insight)
                        testProcessedInsight = insight
                        await processApiData(response.data.data, insight)
                        getInsightTitle()
                        setDropdownSelected(true)

                        // Export Data.
                        if (!isSetUI) {
                            if (exportRef.current) {
                                exportRef.current.click();
                            }
                        }
                    } else {
                        //// console.log(response.data.msg)
                    }
                }

                // Remove Query
            } else {
                console.error('Error fetching data:', 'API call was not successful');
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    // Fetch insight by ID
    const fetchInsightByID = (itemId) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            // Perform your API call here
            getInsightApi(userId, itemId)
                .then((response) => {
                    setLoading(false);
                    if (response.data.result === responses.RESULT_OK) {
                        resolve({ data: response.data.data, success: true });
                    } else {
                        // console.log('false:', response.data.msg);
                        resolve({ data: null, success: false });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error.message);
                    setLoading(false);
                    reject(error);
                });
        });
    };

    // Insight Dropdown manuall selection
    const insightDropdownRef = useRef();
    const vehicleDropdownRef = useRef();

    const [selectedVehicles, setSelectedVehicles] = useState([])

    const [getInsightDropdownData, setInsightDropdownData] = useState(insightDropdownData);
    const selectInsight = async (insightId, insightName) => {
        const updatedData = [...getInsightDropdownData]; // Make a shallow copy of the data array

        // Create an array to store all promises
        const promises = [];

        for (let i = 0; i < updatedData.length; i++) {
            const insight = updatedData[i];
            for (let j = 0; j < insight.submenu.length; j++) {
                const submenu = insight.submenu[j];
                if (submenu.id === insightId || submenu.name === insightName) {
                    // Set isSelected property to true
                    updatedData[i].submenu[j].isSelected = true;

                    // Push the promise into the array
                    promises.push(onInsghtSelect(insightId, submenu, true));
                    insightDropdownRef.current.setPlaceholder(submenu.name); // Update placeholder
                    homePageInsight(submenu.name);
                    getUpdatedInsight(submenu.name);
                }
            }
        }

        // Wait for all promises to resolve
        await Promise.all(promises);
        setInsightDropdownData([...updatedData]); // Update Dropdown data
        // console.log('All step one operations completed');
    };

    const selectFeaturesAndVehicles = async (featureIDs, vehiclesDataOfInsight) => {
        // Create an array to store all promises
        const promises = [];

        // feature selection
        // console.log("featureIDs", featureIDs, featuresData)
        featureIDs.map(featureID => {
            let featureToSelect = featuresData.find(item => item.sbd_feature_id === featureID);

            if (featureToSelect !== undefined) {
                if (featureToSelect.vp_name !== '' && featureToSelect.vp_value === '' && featureToSelect.vp_sub_value === '') {

                    featuresDropdownRef.current.addToBlock2Selected(featureToSelect);
                    // console.log('addToBlock2Selected', featureToSelect.vp_name)

                } else if (featureToSelect.vp_name !== '' && featureToSelect.vp_value !== '' && featureToSelect.vp_sub_value === '') {

                    featuresDropdownRef.current.addToBlock3Selected(featureToSelect);
                    // console.log('addToBlock3Selected', featureToSelect.vp_value)

                } else if (featureToSelect.vp_name !== '' && featureToSelect.vp_value !== '' && featureToSelect.vp_sub_value !== '') {

                    featuresDropdownRef.current.addToBlock4Selected(featureToSelect);
                    // console.log('addToBlock4Selected', featureToSelect.vp_sub_value)
                }
                setFeatureDropdownSelected(true)
            }

            // console.log("Found feature :", featureID, featureToSelect)
        });

        setSelectedVehicles([]);
        // Vehicles
        vehiclesDataOfInsight.map(insightVehicle => {
            let category = insightVehicle.category;
            let values = insightVehicle.values;

            // promises.push(vehicleDropdownRef.current.updateSelectedVehiclesData(category, [...selectedVehicles, { category: category, values: [...values] }], false))
        })

        // Wait for all promises to resolve
        await Promise.all(promises);
        // console.log('All step two and three operations completed');
    };

    let buildForApi = { insight: false, features: false, vehicles: false }
    const updateBuildForApi = (key) => {
        // // console.log('buildForApi: before: ', key, buildForApi)
        buildForApi[key] = true;
        // // console.log('buildForApi: after: ', key, buildForApi)
        if (buildForApi.features) {
            // console.log('inside api calling')
            getInshgtData()
        }
    }


    return (
        <div className="row container-main-margin" data-idd={dataId}>
            <div className={(!isInsightsContainer ? '' : 'hide-tab-div')}>
                {/* Take Tour */}
                <div className="col-12">
                    <center className="mt-3">
                        <button type="button" data-toggle="modal" className="btn tour-text" data-bs-toggle="modal" data-bs-target="#tourModal">Take the 60 second tour to understand how it takes 3-easy steps to answer any query</button>
                    </center>
                </div>

                <div className="col-12">
                    <div className="card mt-4 mb-2 border-0" >
                        <div className="card-body sbd-card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-8 row">
                                    <div style={{ width: '27.33%', paddingRight: 0 }}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='sbd-sm'>Step 1. <img src={greenTick} className={(isInsightDropdownSelected) ? "green-tick-img" : 'green-tick-img hide-tab-div'} alt="" /></div>
                                                <InsightDropdown insightDataFromApi={insightDataFromApi} ref={insightDropdownRef} options={getInsightDropdownData} onInsghtSelect={onInsghtSelect}
                                                    updateBuildForApi={updateBuildForApi} homePageInsight={homePageInsight} getUpdatedInsight={getUpdatedInsight} showMenu={showInsightMenu} setShowMenu={fromInsight} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '27.33%', paddingRight: 0 }}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className={((isInsightDropdownSelected) ? '' : 'disabled-label ') + 'sbd-sm'}>Step 2. <img src={greenTick} className={(isFeatureDropdownSelected && isFeatureDropdownGreeTick) ? "green-tick-img" : 'green-tick-img hide-tab-div'} alt="" /></div>
                                                <FeaturesDropdown insightDataFromApi={insightDataFromApi} ref={featuresDropdownRef} selectedInsightId={selectedInsightId} featuresData={featuresData} featureCategories={featureCategories}
                                                    updateBuildForApi={updateBuildForApi} insightBuilderUpdateFeatures={insightBuilderUpdateFeatures} showMenu={showFeaturesMenu} setShowMenu={fromFeature} setFeatureDropdownSelected={setFeatureDropdownSelected}
                                                    isInsightDropdownSelected={isInsightDropdownSelected} selectedFeatures={selectedFeaturesFD} setSelectedfeatures={setSelectedfeaturesFD} tblVehicles={tblVehicles}
                                                    setVehiclesDropdownSelected={setVehiclesDropdownSelected} featuresNVehicleLoading={featuresNVehicleLoading} block2Data={block2Data} setBlock2Data={setBlock2Data}
                                                    block3Data={block3Data} setBlock3Data={setBlock3Data} block4Data={block4Data} setBlock4Data={setBlock4Data} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: '27.33%', paddingRight: 0 }}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className={((isFeatureDropdownSelected) ? '' : 'disabled-label ') + 'sbd-sm'}>Step 3. <img src={greenTick} className={(isVehiclesDropdownSelected) ? "green-tick-img" : 'green-tick-img hide-tab-div'} alt="" /></div>
                                                <VehiclesDropdown insightDataFromApi={insightDataFromApi} ref={vehicleDropdownRef} selectedInsightId={selectedInsightId} vehicles={vehiclesData} onVehicleSelection={onVehicleSelection}
                                                    updateBuildForApi={updateBuildForApi} homePageVehicles={homePageVehicles} selectedFeatures={selectedFeatures} getUpdatedVehicles={getUpdatedVehicles}
                                                    showMenu={showVehiclesMenu} setShowMenu={fromVehicles} isFeatureDropdownSelected={isFeatureDropdownSelected} setVehiclesDropdownSelected={setVehiclesDropdownSelected}
                                                    selectedVehicles={selectedVehicles} setSelectedVehicles={setSelectedVehicles} />
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: '18%' }}>
                                        <div className='sbd-sm'>&nbsp;</div>
                                        {
                                            (selectedInsightId != 17 && selectedInsightId != 19) ? (
                                                <button className={((isVehiclesDropdownSelected) ? '' : 'disabled-dropdown ') + "btn btn-primary"} onClick={e => getInshgtData()} disabled={(isVehiclesDropdownSelected) ? false : true}>
                                                    {
                                                        (isApiInProgress) && (
                                                            <img src={boxImg} style={{ width: "30px" }}></img>
                                                        )
                                                    }
                                                    {isApiInProgress ? '' : "Build"}
                                                </button>
                                            ) : (
                                                // Else part
                                                <button className={((isVehiclesDropdownSelected) ? '' : 'disabled-dropdown ') + "btn btn-primary"} disabled={(isVehiclesDropdownSelected) ? false : true} onClick={handleShowDownloadModal}>
                                                    {
                                                        (isApiInProgress) && (
                                                            <img src={boxImg} style={{ width: "30px" }}></img>
                                                        )
                                                    }
                                                    {isApiInProgress ? '' : "Build"}
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>

                                {/* <div className='vertical-line'></div> */}
                                <div className="col-md-4 row justify-content-around vertical-line">
                                    <div className="col-md-10 col-md-8-custom pt-2">
                                        <button type="button" data-toggle="modal" className="btn btn-primary button-icons-active" data-bs-toggle="modal" data-bs-target="#newInsightModal" disabled={isDropdownSelected ? '' : 'disabled'}>
                                            <img src={(isDropdownSelected) ? newImg : newInactiveImg} className="button-img" alt='New Insight' />
                                            <span className={(isDropdownSelected) ? "button-text-active" : "button-text-inactive"}>New</span>
                                        </button>
                                        <button type="button" className={(isFavorite ? 'active-btn-custom' : '') + ' button-icons-active btn btn-primary'} onClick={toggleFavorite} disabled={isDropdownSelected ? '' : 'disabled'}>
                                            <img src={(isDropdownSelected) ? (isFavorite ? favSavedImg : favImg) : favInactiveImg} className="button-img" alt='Favorite Insight' />
                                            <span className={(isDropdownSelected) ? "button-text-active" : "button-text-inactive"}>{isFavorite ? 'Remove' : 'Favorite'}</span>
                                        </button>
                                        <span className={(isInsights ? 'open' : '')}>
                                            <button type="button" data-toggle="dropdown" className={(isInsights ? 'active-btn-custom' : '') + ' button-icons-active btn btn-primary'} onClick={toggleInsights}>
                                                <img src={isInsights ? recentWhiteImg : recentImg} className="button-img" alt='Insights' />
                                                <span className="button-text-active">Insights</span>
                                            </button>
                                            <div className="dropdown-menu recent-div" style={{ left: 'auto' }}>
                                                <div className="row recent-row" style={{ padding: '0px 11px 0px 13px' }}>
                                                    <div className={(!isFavoritesTab ? '' : 'recent-inactive-tab') + ' col-sm-6 recent-tabs'} onClick={toggleRecentTab}>Recent</div>
                                                    <div className={(isFavoritesTab ? '' : 'recent-inactive-tab') + ' col-sm-6 recent-tabs recent-border'} onClick={toggleFavoritesTab}>Favorites</div>
                                                </div>
                                                <div className={(!isFavoritesTab ? '' : 'hide-tab-div')}>
                                                    {
                                                        insightListData &&
                                                        insightListData.map(item => {
                                                            // Parse the original date string into a Date object
                                                            const dateObject = new Date(item.created_at);

                                                            // Format the date according to the desired format
                                                            const day = dateObject.getDate();
                                                            const month = dateObject.getMonth() + 1; // Months are zero-based
                                                            const year = dateObject.getFullYear();

                                                            // Create the formatted date string
                                                            const formattedDateString = `${day}/${month}/${year}`;

                                                            if (item.insight_name) {
                                                                return (
                                                                    <div key={item.id} className="recent-container">
                                                                        <div className="recent-title" onClick={() => setDataAndLoadTable(item.id)}>{item.insight_name}</div>
                                                                        <div className="recent-date">{formattedDateString}</div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                                <div className={(isFavoritesTab ? '' : 'hide-tab-div')}>
                                                    {
                                                        favInsightListData &&
                                                        favInsightListData.map(item => {
                                                            // Parse the original date string into a Date object
                                                            const dateObject = new Date(item.created_at);

                                                            // Format the date according to the desired format
                                                            const day = dateObject.getDate();
                                                            const month = dateObject.getMonth() + 1; // Months are zero-based
                                                            const year = dateObject.getFullYear();

                                                            // Create the formatted date string
                                                            const formattedDateString = `${day}/${month}/${year}`;

                                                            if (item.insight_name) {
                                                                return (
                                                                    <div key={item.id} className="recent-container">
                                                                        <div title={item.insight_name} className="recent-title" onClick={() => setDataAndLoadTable(item.id)}>
                                                                            <img src={favSavedImg} className="recent-img" />
                                                                            {item.insight_name}
                                                                        </div>
                                                                        <div className="recent-date">
                                                                            {formattedDateString}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                </div>
                                                <div className="recent-show-insights" onClick={showInsightsContainer}>
                                                    Show all insights
                                                </div>
                                            </div>
                                        </span>
                                        {/* <DownloadTableExcel
                                            filename="VehiclePlannerPlus Insight "
                                            sheet="Insight"
                                            currentTableRef={ isTrimShare == false ? resultTableRef.current : resultTrimShareTableRef.current}
                                        >
                                            <button ref={exportRef} type="button" data-toggle="modal" className="btn btn-primary button-icons-active" data-bs-toggle="modal" data-bs-target="#exampleModal" disabled={isDropdownSelected && resultData.length > 0 ? '' : 'disabled'}>
                                                <img src={(isDropdownSelected && resultData.length > 0) ? export_new_skinImg : exportInactiveImg} className="button-img" alt='Export Insight' />
                                                <span className={(isDropdownSelected && resultData.length > 0) ? "button-text-active" : "button-text-inactive"}>Export</span>
                                            </button>
                                        </DownloadTableExcel> */}

                                        <DownloadTableExcelSheetJS
                                            filename="VehiclePlannerPlus Insight.xlsx"
                                            sheet="Insights"
                                            currentTableRef={isTrimShare === false ? resultTableRef.current : resultTrimShareTableRef.current}
                                        >
                                            <button ref={exportRef} type="button" data-toggle="modal" className="btn btn-primary button-icons-active" data-bs-toggle="modal" data-bs-target="#exampleModal" disabled={isDropdownSelected && resultData.length > 0 ? '' : 'disabled'}>
                                                <img src={(isDropdownSelected && resultData.length > 0) ? export_new_skinImg : exportInactiveImg} className="button-img" alt='Export Insight' />
                                                <span className={(isDropdownSelected && resultData.length > 0) ? "button-text-active" : "button-text-inactive"}>Export</span>
                                            </button>
                                        </DownloadTableExcelSheetJS>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SaveInsightComponent insightTitle={insightTitle} insightPayloadData={insightPayloadData} setInsightPayloadData={setInsightPayloadData} ref={saveModalRef} setIsFavorite={setIsFavorite} handleOuterComponentClick={handleOuterComponentClick} />
                <ModalComponent insightTitle={insightTitle} handleShowSaveModal={handleShowSaveModal} resetAll={resetAll} ref={innerComponentRef} />
                <DownloadComponent ref={downloadModalRef} getInshgtData={getInshgtData} />
                <DownloadProcessComponent ref={downloadProcessModalRef} getInshgtData={getInshgtData} />
                <TourModalComponent />
                {
                    isTrimShare == false && <ResultTable features={features} insight={insight} vehicles={vehicles} headerFeatures={resultTblHeaders}
                    headersWithValues={headersWithValues} resultData={resultData} vehKeys={vehKeys} featureIds={featureIds}
                    tblVehicles={tblVehicles} processedInsight={processedInsight} insightTitle={insightTitle} handleShowSaveModal={handleShowSaveModal}
                    sortResultTable={sortResultTable} filterBasedOnCheckBoxes={filterBasedOnCheckBoxes} filterResultTable={filterResultTable} resultTableRef={resultTableRef} vehicleDropdownData={vehiclesData} selectedInsightId={selectedInsightId} />
                }
                {
                    isTrimShare && <TrimShareTable trimData={trimShareData} resultTrimShareTableRef={resultTrimShareTableRef} />
                }
                
            </div >
            <div className={(isInsightsContainer ? '' : 'hide-tab-div') + ' insights-container'} >
                <div className="container container-sm container-cm-custom">
                    <div>
                        <div className="row">
                            <div className="col-md-5">
                                <span className="glyphicon glyphicon-arrow-left">
                                </span>&nbsp;&nbsp;
                                <button className="btn-link backToMain-btn" onClick={showMainContainer}> BACK TO BUILD</button>
                                <div>
                                </div>
                            </div>
                            <div className="col-md-5"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={(!isInsightsContainerFavoritesTab ? 'fav-active-tab' : 'fav-in-active-tab') + ' col-sm-6 fav-tabs '} onClick={showInsightsContainerRecentTab}>
                            Recent
                        </div>
                        <div className={(isInsightsContainerFavoritesTab ? 'fav-active-tab' : 'fav-in-active-tab') + ' col-sm-6 fav-tabs '} onClick={showInsightsContainerFavoritesTab}>
                            Favorites
                        </div>
                    </div>

                    {
                        /* Full Insight Section START */

                        fullInsightListData &&
                        fullInsightListData.map(item => {
                            // Parse the original date string into a Date object
                            const dateObject = new Date(item.created_at);

                            // Format the date according to the desired format
                            const day = dateObject.getDate();
                            const month = dateObject.getMonth() + 1; // Months are zero-based
                            const year = dateObject.getFullYear();

                            // Create the formatted date string
                            const formattedDateString = `${day}/${month}/${year}`;

                            if (item.insight_name) {
                                return (
                                    <div key={item.id} className={(!isInsightsContainerFavoritesTab ? '' : 'hide-tab-div') + ' row'} >
                                        <div className="col-md-12 card-row">
                                            <div className="card-border">
                                                <div className="row p-2">
                                                    <div className="col-md-12 card-content-common">
                                                        <p className="insight-title">{item.insight_name}</p>
                                                        <div className="row">
                                                            <p></p>
                                                            <div className="col-md-3 max-whidth-100">
                                                                <p className="date-p">{formattedDateString}</p>
                                                            </div>
                                                            {/* <span className="insight-options"> */}
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" onClick={() => setDataAndLoadTable(item.id, false)}>Export data</p>
                                                            </div>
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" onClick={() => setDataAndLoadTable(item.id)}>Load table</p>
                                                            </div>
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" >Favourite</p>
                                                            </div>
                                                            {/* </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }

                    {
                        /* Full Favorites Insight Section START */

                        fullFavInsightListData &&
                        fullFavInsightListData.map(item => {
                            // Parse the original date string into a Date object
                            const dateObject = new Date(item.created_at);

                            // Format the date according to the desired format
                            const day = dateObject.getDate();
                            const month = dateObject.getMonth() + 1; // Months are zero-based
                            const year = dateObject.getFullYear();

                            // Create the formatted date string
                            const formattedDateString = `${day}/${month}/${year}`;

                            if (item.insight_name) {
                                return (
                                    <div key={item.id} className={(isInsightsContainerFavoritesTab ? '' : 'hide-tab-div') + ' row'} >
                                        <div className="col-md-12 card-row">
                                            <div className="card-border">
                                                <div className="row p-2">
                                                    <div className="col-md-12 card-content-common">
                                                        <p className="insight-title">
                                                            <img src={favSavedImg} className="recent-img" />
                                                            {item.insight_name}
                                                        </p>
                                                        <div className="row">
                                                            <p></p>
                                                            <div className="col-md-3 max-whidth-100">
                                                                <p className="date-p">{formattedDateString}</p>
                                                            </div>
                                                            {/* <span className="insight-options"> */}
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" onClick={() => setDataAndLoadTable(item.id, false)}>Export data</p>
                                                            </div>
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" onClick={() => setDataAndLoadTable(item.id)}>Load table</p>
                                                            </div>
                                                            <div className="col-md-3 insight-action-div">
                                                                <p className="insight-action-text" >Remove</p>
                                                            </div>
                                                            {/* </span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }

                    {/* End - */}
                </div>
            </div>
        </div >
    )
}

export default InsightBuilder
